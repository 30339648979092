import React,{Component} from 'react';

import {Link} from 'react-router-dom';

class SearchMenu extends Component {

    render() {

        const {songs, artists, albums} = this.props.data;
        
        return (

            <div>

                {songs && artists  && albums &&
                
                <div className="search-menu">

                    {songs.data.length <= 0 && artists.data.length <= 0 && albums.data.length <= 0  &&
                    
                        <ul className="search-ui">

                            <li>No search result found</li>

                        </ul>
                
                    }

                    {songs.data.length > 0 && 
                        <ul className="search-ui">
                            
                            <li className="search-heading">{songs.name} <Link to={'/trending'} className="search-see-all float-right">see all</Link></li>
                            {songs.data.map(song => (
                                <li key={song.song_id}>
                                    <Link to={`/music/${song.song_id}`}>
                                        {song.title}
                                    </Link>
                                </li>
                            ))}

                        </ul>
                    }
                    {artists.data.length > 0 && 
                        <ul className="search-ui">
                            <li className="search-heading">{artists.name} <Link to={`/artists/${artists.term}`} className="search-see-all float-right">see all</Link></li>
                            {artists.data.map(artist => (
                            <li key={artist.artist_id}>
                                <Link to={`/artist-view/${artist.artist_id}`}>
                                    {artist.name}
                                </Link>
                            </li>
                            ))}
                        </ul>
                    }
                    {albums.data.length > 0 && 
                    <ul className="search-ui">
                        <li className="search-heading">{albums.name} <Link to={`/albums/${albums.term}`} className="search-see-all float-right">see all</Link></li>
                        {albums.data.map(album => (
                            <li key={album.movie_album_id}>
                                <Link to={`/album-view/${album.movie_album_id}`}>
                                {album.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    }
                </div>

                }

            </div>

        )

    }

}
export default SearchMenu;