import React, {Component} from 'react';

import {Link} from 'react-router-dom';

class Error extends Component {
    render() {
        return (
            <div className="error-wrapper">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-5 error-sec justify-content-right">
                        <img src="../../assets/img/404.png" alt="error-img" className="error-img" />
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-4 error-sec">
                        <div className="p-5">
                            <h1>Awww...Don't Cry.</h1>
                            <h4>It's just a 404 Error!</h4>
                            <h4>What you are looking for may have been misplaced in Long Term Memory!</h4>
                            <Link to={'/'} className="btn btn-warning btn-solid">go home</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Error;

