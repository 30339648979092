import React, {Component} from 'react';
import { Link , Redirect } from 'react-router-dom';

import api from '../../Environment';

const $ = window.$;

class ForgetPassword extends Component{

    constructor(props) {

        super(props);

        this.state = {

            forgot_password_form : {

                email : "",

            },

            redirect : false

        }

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {

        const { name, value } = event.target;

        const { forgot_password_form } = this.state;

        this.setState({ 
            forgot_password_form : {...forgot_password_form, [name]: value }
        });

    }

    closeForgot() {

        $("#forgot-close").click();

    }

    handleSubmit(event) {

        event.preventDefault();

        if (!event.target.checkValidity()) {

            // form is invalid! so we do nothing

            return;

        }

        // form is valid! We can parse and submit data

        api.postMethod("forgot_password", this.state.forgot_password_form)
        .then(

            (response)=> {

                if (response.data.success) {

                    $("#forgot-close").click();

                    $.toast({
                        heading: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                    this.setState({redirect : true});

                } else {

                    $.toast({
                        heading: 'Error',
                        text: response.data.error,
                        icon: 'error',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                }

            },
            (error) => {

                console.log(error);

            },
        )
        .catch(
            (err) => {

               console.log("oops something went wrong");

            }
        );
    }

    render() {

        if (this.state.redirect) {

            return <Redirect to={'/'} />;
            
        }

        return (

            <div className="modal fade" id="forgot">        
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Reset Password</h4>
                            <button type="button" className="close" data-dismiss="modal" id="forgot-close">&times;</button>
                        </div>
                        <div className="modal-body auth-form">
                            <form onSubmit={this.handleSubmit}>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <img src="assets/icons/email-gold.png" className="auth-img" alt="Email"/>
                                        </span>
                                    </div>
                                    <input type="email" className="form-control" placeholder="Email" maxLength="128" minLength="4" title="Enter minimum 4 to maximum 128 characters" required name="email" onChange={this.handleChange} />
                                </div>
                                <div>
                                    <p>Enter the email address associated with your account, and we’ll email you a link to reset your password</p>
                                </div>
                                <div className="text-right">
                                    <p className="mt-3 mb-0 capitalize">already have an account?<Link to="#" className="link" data-toggle="modal" data-target="#login" id="forgot-login-link" onClick={this.closeForgot}>&nbsp;login</Link></p>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-warning btn-solid">Reset Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        )

    }

}

export default ForgetPassword;