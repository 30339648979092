import React, {Component} from 'react';

import {NavLink, Link, Redirect} from 'react-router-dom';

import api from '../../Environment';

import {apiConstants} from '../Constant/Constants';
import configuration from "react-global-configuration";

const $ = window.$;

class LeftSidebar extends Component {

    constructor(props) {

        super(props);

        this.state = {
            
            isAuthenticated : this.props.data,

            redirect : false,

            page_items : []
            
        };

        this.logout = this.logout.bind(this);

    }

    logout(e) {

        e.preventDefault();

        api.postMethod("logout", {})
        .then(
            (response) => {

                if (response.data.success) {

                    this.setState({

                        isAuthenticated : false,

                        redirect : true

                    });

                    localStorage.removeItem('userId');

                    localStorage.removeItem('accessToken');

                    localStorage.removeItem('queue_songs');

                    $.toast({
                        heading: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                } else {

                    this.setState({

                        isAuthenticated : false,

        
                    });

                    $.toast({
                        heading: 'Error',
                        text: response.data.error,
                        icon: 'error',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                }

            },

            (error) => {

                console.log(error);

            },
        )
        .catch(
            (err) => {

                console.log("oops something went wrong");

            }
        );

    }


    pagesList() {

        api.getMethod("pages/list",{})
        .then(
            (response) => {

                if (response.data.success) {

                    this.setState({
                        
                        page_items : response.data.data,

                    });

                } else {

                    console.log(response.data.error);

                }

            },

            (error) => {

                console.log(error);

            },
        )
        .catch(
            (err) => {

                console.log( "oops something went wrong" );

            }
        );

    }

    componentDidMount() {

        this.pagesList();

    }

    render() {

        const {page_items, isAuthenticated } = this.state;
        

        if (this.state.redirect) {

            return <Redirect to={'/'} />;

        }

        return (

            <div id="sidebar">
                <Link to={"/"}> 
                    <img src={configuration.get("configData.site_logo")} className="site-logo" alt="Logo"/>
                </Link>
                <div className="sidebar-menu">
                    <ul>
                        <li>
                            <NavLink to={"/home"} activeClassName="active">
                                <img src="../../assets/icons/home-gold.png" className="navigation-icon gold-icon" alt="Home"/>
                                <img src="../../assets/icons/home-white.png" className="navigation-icon white-icon" alt="Home"/>
                                <span className="navigation-text">home</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/trending"} activeClassName="active">
                                <img src="../../assets/icons/trending-gold.png" className="navigation-icon gold-icon" alt="Trending"/>
                                <img src="../../assets/icons/trending-white.png" className="navigation-icon white-icon" alt="Trending"/>
                                <span className="navigation-text">Trending</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/categories'} activeClassName="active">
                                <img src="../../assets/icons/category-gold.png" className="navigation-icon gold-icon" alt="Category"/>
                                <img src="../../assets/icons/category-white.png" className="navigation-icon white-icon" alt="Category"/>
                                <span className="navigation-text">category</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/artists'} activeClassName="active">
                                <img src="../../assets/icons/artist-gold.png" className="navigation-icon gold-icon" alt="Artist"/>
                                <img src="../../assets/icons/artist-white.png" className="navigation-icon white-icon" alt="Artist"/>
                                <span className="navigation-text">artist</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/albums'} activeClassName="active">
                                <img src="../../assets/icons/album-gold.png" className="navigation-icon gold-icon" alt="Album"/>
                                <img src="../../assets/icons/album-white.png" className="navigation-icon white-icon" alt="Album"/>
                                <span className="navigation-text">albums</span>
                            </NavLink>
                        </li>

                        { isAuthenticated && 
                        <li>
                            <NavLink to={"/subscriptions"} activeClassName="active">
                                <img src="../../assets/icons/subscription-gold.png" className="navigation-icon gold-icon" alt="Subscription"/>
                                <img src="../../assets/icons/subscription-white.png" className="navigation-icon white-icon" alt="Subscription"/>
                                <span className="navigation-text">Subscription</span>
                            </NavLink>
                        </li> }

                        { /*isAuthenticated && 
                        <li>
                            <NavLink to={"/downloads"} activeClassName="active">
                                <img src="../../assets/icons/download-arrow-gold.png" className="navigation-icon gold-icon" alt="downloads"/>
                                <img src="../../assets/icons/download-arrow-white.png" className="navigation-icon white-icon" alt="downloads"/>
                                <span className="navigation-text">downloads</span>
                            </NavLink>
                        </li> */ }

                        

                        { isAuthenticated && 
                        <li>
                            <Link to={"/"} onClick={this.logout}>
                                <img src="../../assets/icons/logout-gold.png" className="navigation-icon gold-icon" alt="logout"/>
                                <img src="../../assets/icons/logout-white.png" className="navigation-icon white-icon" alt="logout"/>
                                <span className="navigation-text">logout</span>
                            </Link>
                        </li> }

                        { !isAuthenticated &&  <li className="divider"></li> }
                        
                        { !isAuthenticated && 
                        <li className="extra-content">
                            <div className="text-center"> 
                                <img src="../../assets/icons/queue-gold.png" className="queue-icon" alt="music-icon" />
                                <p>sign in to see your playlists</p>
                                <Link to="#" data-toggle="modal" data-target="#login" className="btn btn-solid btn-warning m-0">
                                    sign in
                                </Link>
                            </div>
                        </li>
                        }
                        
                        <li className="divider"></li>
                    </ul>
                    {/* <div className="static-height"></div> */}
                    <div className="terms-sec">
                        {page_items.map(page_item => { 

                           if([apiConstants.PRIVACY_PAGE_TYPE, apiConstants.TERMS_PAGE_TYPE, apiConstants.ABOUT_PAGE_TYPE, apiConstants.HELP_PAGE_TYPE, apiConstants.CONTACT_PAGE_TYPE].includes(page_item.page_type)) {
                                return (<div><NavLink to={`/page/${page_item.page_id}`} activeClassName="active" key={page_item.page_type}>
                                    <span className="navigation-text">{page_item.title}</span>
                                </NavLink></div>)
                           }
                            
                        })}
                    </div>
                    <div className="body-height"></div>
                </div>
            </div>

        );

    }

}


export default LeftSidebar;