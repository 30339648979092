import React, {Component} from 'react';

import api from '../../Environment';

const $ = window.$;

class ChangePassword extends Component {

    constructor(props) {

        super(props);

        this.eventEmitter = this.props.eventEmitter;

        this.passwordUpdate = this.passwordUpdate.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.state = {

            password_form : {

                old_password : "",

                password : "",

                password_confirmation : "",

            }
        }

    }

    handleChange(e) {

        e.preventDefault();

        const { name, value } = e.target;

        const {password_form} = this.state;

        this.setState({ 
            password_form : {...password_form, [name]: value }
        });

    }

    passwordUpdate(e) {

        e.preventDefault();

        if (!e.target.checkValidity()) {

            // form is invalid! so we do nothing

            return;

        }

        api.postMethod("change_password", this.state.password_form)
        .then(

            (response)=> {

                if (response.data.success) {

                    this.eventEmitter.emit("logout_fn", response.data.success);

                    $.toast({
                        heading: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                } else {

                    $.toast({
                        heading: 'Error',
                        text: response.data.error,
                        icon: 'error',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                }

            },
            (error) => {

                console.log(error);


            },
        )
        .catch(
            (err) => {

                console.log("oops something went wrong");

            }
        );

    }

    render() {

        return (

            <div className="body-content">
                <div className="music-section">
                    <h2>change password</h2>
                    <p className="capitalize mb-4">Note: Once you changed password, your session will logout.</p>
                    <div className="row">
                        <div className="col-sm-12 col-md-8 col-lg-10 col-xl-8">
                            <form className="auth-form" onSubmit={this.passwordUpdate}>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <img src="../../assets/icons/lock-gold.png" className="auth-img" alt="icon" />
                                        </span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="old password" name="old_password" maxLength="128" minLength="6" title="Enter minumum 6 and maximum 128 characters" onChange={this.handleChange} required/>
                                </div>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <img src="../../assets/icons/lock-gold.png" className="auth-img" alt="icon" />
                                        </span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="new password" name="password" maxLength="128" minLength="6" title="Enter minumum 6 and maximum 128 characters" onChange={this.handleChange} required/>
                                </div>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <img src="../../assets/icons/lock-gold.png" className="auth-img" alt="icon" />
                                        </span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="confirm password" name="password_confirmation" maxLength="128" minLength="6" title="Enter minumum 6 and maximum 128 characters" onChange={this.handleChange} required/>
                                </div>
                                <div className="">
                                    <button className="btn btn-warning mt-0 btn-solid">change password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>					
            </div>

            
        );

    }
}

export default ChangePassword;