import React, { Component } from "react";

import { Link } from "react-router-dom";

import Background from "../../assets/img/banner-artist.png";

import api from "../../Environment";

var sectionStyle = {
  backgroundImage: `url(${Background})`
};

const $ = window.$;

class ArtistView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error_messages: "",

      isLoaded: false,

      items: [],

      artist_details: "",

      isAuthenticated: this.props.data,

      sectionLoader: false,

      skipCount: 0
    };

    this.eventEmitter = this.props.eventEmitter;

    this.showMore = this.showMore.bind(this);

    this.wishlistOperation = this.wishlistOperation.bind(this);
  }

  wishlistOperation(e, song_id) {
    e.preventDefault();

    var currentTarget = e.target;

    api
      .postMethod("wishlist/operations", { song_id: song_id })
      .then(
        response => {
          if (response.data.success) {
            console.log(response.data);

            currentTarget.textContent =
              response.data.wishlist_id > 0
                ? "remove from Favorite"
                : "add to Favorite";
          } else {
            console.log(response.data.error);
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  artistViewDetails(skip) {
    if (this.state.skipCount > 0) {
      this.setState({
        sectionLoader: true
      });
    }

    const {
      match: { params }
    } = this.props;

    api
      .postMethod("artist/songs", { artist_id: params.id, skip: skip })
      .then(
        response => {
          if (response.data.success) {
            if (this.state.skipCount > 0) {
              var items = [...this.state.items, ...response.data.data];

              this.setState({
                isLoaded: true,

                items: items,

                artist_details: response.data.artist_detail,

                skipCount: this.state.skipCount + response.data.data.length,

                sectionLoader: false
              });
            } else {
              this.setState({
                isLoaded: true,

                items: response.data.data,

                artist_details: response.data.artist_detail,

                skipCount: response.data.data.length
              });
            }
          } else {
            this.setState({
              isLoaded: true,

              error_messages: response.data.error
            });
          }
        },
        error => {
          console.log(error);

          this.setState({
            isLoaded: true,

            error_messages: error
          });
        }
      )
      .catch(err => {
        this.setState({
          isLoaded: true,

          error_messages: "oops something went wrong"
        });
      });
  }

  addQueue(e, object) {
    e.preventDefault();

    var queue_songs = [];

    queue_songs = localStorage.getItem("queue_songs")
      ? JSON.parse(localStorage.getItem("queue_songs"))
      : [];

    let already_value_exists = 0;

    if (queue_songs.length > 0) {
      if (queue_songs.some(item => item.song_id === object.song_id)) {
        already_value_exists = 1;
      }
    }

    if (!already_value_exists) {
      this.eventEmitter.emit("queue_listen", object);

      $.toast({
        heading: "Success",
        text: "Added to queue",
        icon: "success",
        position: "top-right",
        stack: false,
        textAlign: "left",
        loader: false,
        showHideTransition: "slide"
      });
    } else {
      $.toast({
        heading: "Error",
        text: "Already Added to the queue",
        icon: "error",
        position: "top-right",
        stack: false,
        textAlign: "left",
        loader: false,
        showHideTransition: "slide"
      });
    }
  }

  removeFromQueue(e, song_id) {
    e.preventDefault();

    var queue_songs = localStorage.getItem("queue_songs")
      ? JSON.parse(localStorage.getItem("queue_songs"))
      : [];

    if (queue_songs.length > 0) {
      for (var index = 0; index < queue_songs.length; index++) {
        var item = queue_songs[index];

        if (item.song_id === song_id) {
          queue_songs.splice(index, 1);

          localStorage.setItem("queue_songs", JSON.stringify(queue_songs));

          queue_songs = localStorage.getItem("queue_songs")
            ? JSON.parse(localStorage.getItem("queue_songs"))
            : [];

          this.setState({
            queue_songs: queue_songs
          });

          this.eventEmitter.emit("remove_queue_listen", true);

          $.toast({
            heading: "Success",
            text: "Removed from the queue",
            icon: "success",
            position: "top-right",
            stack: false,
            textAlign: "left",
            loader: false,
            showHideTransition: "slide"
          });
        }
      }
    }
  }

  showMore(e) {
    this.artistViewDetails(this.state.skipCount);
  }

  componentDidMount() {
    this.artistViewDetails(0);
  }

  render() {
    const {
      error_messages,
      isLoaded,
      items,
      artist_details,
      isAuthenticated,
      sectionLoader
    } = this.state;

    if (error_messages) {
      return <div className="body-content">Error : {error_messages}</div>;
    } else if (!isLoaded) {
      return (
        <div className="body-content">
          <div className="pl-3 pr-3">
            <div className="section-loader">
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="body-content">
          <div
            className="see-all-banner"
            style={
              artist_details.picture
                ? { backgroundImage: `url(${artist_details.picture})` }
                : sectionStyle
            }
          >
            <div className="see-all-overlay">
              <div>
                <h2>{artist_details.name}</h2>
                <h4>{artist_details.song_artists_count} songs</h4>
                <div className="button-sec">
                  {/* <button className="btn btn-warning btn-solid">play all</button>
                                    <button className="btn btn-warning btn-outline" disabled title="This Option Only avilable in App's">download</button> */}
                </div>
              </div>
            </div>
          </div>

          {items.map((item, index) => (
            <div key={index}>
              <div className="display-inline song-list">
                <Link to={`/music/${item.song_id}`}>
                  <div className="queue-sec1">
                    <span>{index + 1}</span>
                    <div className="relative inline">
                      <img
                        src={item.picture}
                        onError={e => {
                          e.target.onError = "";
                          e.target.src = "../../assets/img/song1.jpg";
                        }}
                        className="song-img"
                        alt={item.title}
                      />
                      <img
                        src="../../assets/icons/playicon1.png"
                        className="song-play-icon"
                        alt="play_icon"
                      />
                    </div>
                  </div>
                  <div className="queue-sec2">
                    <h4>{item.title}</h4>
                    <h5
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </Link>
                <div className="queue-sec3 list">
                  <Link
                    to="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <img
                      src="../../assets/icons/more-horizondal-grey.png"
                      alt="download-arrow"
                    />
                  </Link>
                  <div className="dropdown-menu">
                    <div
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#sharebtn"
                      onClick={e =>
                        this.eventEmitter.emit("share_details", item)
                      }
                    >
                      share
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={e => this.addQueue(e, item)}
                    >
                      add to queue
                    </div>
                    {/*<div className="dropdown-item" onClick={e => this.removeFromQueue(e,item.song_id)}>remove from queue</div> */}
                    {isAuthenticated && (
                      <div
                        className="dropdown-item"
                        onClick={e => this.wishlistOperation(e, item.song_id)}
                      >
                        {item.wishlist_status > 0
                          ? "remove from Favorite"
                          : "add to Favorite"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <p className="queue-line" />
            </div>
          ))}

          {sectionLoader && (
            <div className="pl-3 pr-3">
              <div className="section-loader">
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
              </div>
            </div>
          )}

          {items.length > 0 && (
            <div className="text-right">
              <button
                className="btn btn-solid btn-warning mr-0"
                onClick={e => this.showMore(e)}
              >
                show more
              </button>
            </div>
          )}
        </div>
      );
    }
  }
}

export default ArtistView;
