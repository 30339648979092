import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";

import api from "../../Environment";
import configuration from "react-global-configuration";

const $ = window.$;

class Language extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],

      redirect: false,

      isLoaded: false,

      checkedItems: [],

      cardPairs: [],

      is_user: 0
    };

    this.languageUpdate = this.languageUpdate.bind(this);

    this.onChange = this.onChange.bind(this);
  }

  language_list() {
    api
      .postMethod("languages/list", {})
      .then(
        response => {
          if (response.data.success) {
            var languages = [];

            for (var i = 0; i < response.data.data.length; i++) {
              var language = response.data.data[i];

              if (language.user_language_status) {
                languages.push(language.language_id);
              }
            }

            this.setState({
              items: response.data.data,

              isLoaded: true,

              checkedItems: languages
            });

            console.log("All state data", this.state);
          } else {
            console.log(response.data.error);
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  onChange(event, index) {
    let languages = this.state.checkedItems;

    if (event.target.checked) {
      languages.push(event.target.value);
      this.setState({ checkedItems: languages });
      console.log("Checked");
    } else {
      const filteredItems = languages.filter(
        item => item != event.target.value
      );
      this.setState({ checkedItems: filteredItems });
    }

    // const languages = this.state.checkedItems.slice();

    // if (languages.includes(event.target.value)) {
    //   this.setState({
    //     checkedItems: languages.filter(el => el !== event.target.value)
    //   });
    //   console.log("First if condition", this.state.checkedItems);
    // } else {
    //   // Otherwise, add more elements to the list.

    //   if (event.target.checked) {
    //     this.setState({ checkedItems: languages.concat(event.target.value) });
    //     console.log("Second if condition", this.state.checkedItems);
    //   } else {
    //     //var index = languages.indexOf(event.target.value);

    //     if (index !== -1) languages.splice(index, 1);

    //     this.setState({ checkedItems: languages });
    //     console.log("Third if condition", this.state.checkedItems);
    //   }
    // }
    console.log("Checked list,", this.state.checkedItems);
  }

  languageUpdate(e) {
    e.preventDefault();

    if (this.state.checkedItems.length <= 0) {
      console.log("select any one language");

      $.toast({
        heading: "Error",
        text: "Please select any one of the language",
        icon: "error",
        position: "top-right",
        stack: false,
        textAlign: "left",
        loader: false,
        showHideTransition: "slide"
      });

      return false;
    }

    api
      .postMethod("languages/update", { language_ids: this.state.checkedItems })
      .then(
        response => {
          if (response.data.success) {
            $.toast({
              heading: "Success",
              text: response.data.message,
              icon: "success",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });

            this.setState({ redirect: true });
          } else {
            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  componentDidMount() {
    this.language_list();

    const {
      match: { params }
    } = this.props;

    this.setState({
      is_user: params.is_user ? 1 : 0
    });
  }

  render() {
    const { items, isLoaded, is_user, redirect } = this.state;

    if (redirect) {
      if (is_user) {
        return <Redirect to={"/"} />;
      } else {
        return <Redirect to={"/welcome"} />;
      }
    }

    if (!isLoaded) {
      return (
        <div className="language-wrapper">
          <div className="pl-3 pr-3">
            <div className="section-loader">
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="language-wrapper">
          <div className="">
            <Link to={"/"}>
              <img
                src={configuration.get("configData.site_logo")}
                className="site-logo welcome-logo"
                alt={configuration.get("configData.site_name")}
              />
            </Link>
            <div className="text-center p-3">
              <h1 className="capitalize">choose your preffered language</h1>
            </div>
            <div className="text-center">
              <div className="max-width-400">
                {items.length <= 0 && (
                  <div className="no-resultsec">
                    <img
                      src="../../assets/img/no-result.png"
                      className="no-result-img"
                      alt="no-result"
                    />
                    <h4>Languages is empty</h4>
                    <p>Please contact admin</p>
                  </div>
                )}
                <form onSubmit={this.languageUpdate}>
                  <div className="row language-sec">
                    {items.length > 0 &&
                      items.map((item, index) => (
                        <div className="col-6 col-sm-6" key={index}>
                          <div className="language-btn">
                            {item.user_language_status ? (
                              <input
                                className="inp-cbx dis-none"
                                id={item.language_id}
                                type="checkbox"
                                name="language_id"
                                value={item.language_id}
                                onClick={e => this.onChange(e, index)}
                                defaultChecked
                              />
                            ) : (
                              <input
                                className="inp-cbx dis-none"
                                id={item.language_id}
                                type="checkbox"
                                name="language_id"
                                value={item.language_id}
                                onClick={e => this.onChange(e, index)}
                              />
                            )}
                            <label className="cbx" htmlFor={item.language_id}>
                              <span>
                                <svg
                                  width="12px"
                                  height="10px"
                                  viewBox="0 0 12 10"
                                >
                                  <polyline points="1.5 6 4.5 9 10.5 1" />
                                </svg>
                              </span>
                              <span className="capz">{item.name}</span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="p-2 text-center">
                    <button className="btn btn-solid btn-warning">apply</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Language;
