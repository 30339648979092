import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import api from "../../Environment";
import SearchMenu from "../Menus/SearchMenu";

import { apiConstants } from "../Constant/Constants";

const $ = window.$;

class Header extends Component {
  constructor(props) {
    super(props);

    this.eventEmitter = this.props.eventEmitter;

    this.state = {
      value: "",

      suggestions: [],

      headerHeight: 0,

      isAuthenticated: this.props.data,

      redirect: false,

      language_redirect: false,

      user_details: {},

      search_key: "",

      search_redirect: false,

      displaySearch: false
    };

    this.logout = this.logout.bind(this);

    this.searchResult = this.searchResult.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.escFunction = this.escFunction.bind(this);

    this.setWrapperRef = this.setWrapperRef.bind(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  logout() {
    // e.preventDefault();

    api
      .postMethod("logout", {})
      .then(
        response => {
          if (response.data.success) {
            /*this.setState({

                        isAuthenticated : false,

                        redirect : true

                    }); */

            localStorage.removeItem("userId");

            localStorage.removeItem("accessToken");

            localStorage.removeItem("queue_songs");

            $.toast({
              heading: "Success",
              text: response.data.message,
              icon: "success",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });

            setTimeout(() => {
              window.location.reload(true);
            }, 1000);
          } else {
            this.setState({
              isAuthenticated: false
            });

            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },

        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  userProfile() {
    api
      .postMethod("profile", {})
      .then(
        response => {
          if (response.data.success) {
            localStorage.setItem("accessToken", response.data.data.token);

            localStorage.setItem("userId", response.data.data.user_id);

            this.setState({
              user_details: response.data.data
            });
          } else {
            // Language not yet updated

            if (response.data.error_code === 1005) {
              this.setState({
                language_redirect: true
              });
            } else if (
              response.data.error_code === 1003 ||
              response.data.error_code === 1002 ||
              response.data.error_code === 1004
            ) {
              this.setState({
                redirect: true
              });

              localStorage.removeItem("userId");

              localStorage.removeItem("accessToken");

              localStorage.removeItem("queue_songs");

              $.toast({
                heading: "Success",
                text: response.data.error,
                icon: "success",
                position: "top-right",
                stack: false,
                textAlign: "left",
                loader: false,
                showHideTransition: "slide"
              });

              setTimeout(() => {
                window.location.reload(true);
              }, 1000);
            }
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  searchResult(e) {
    e.preventDefault();

    if (this.state.search_key) {
      this.setState({
        search_redirect: true
      });
    }
  }

  handleChange(e) {
    this.setState({
      search_key: e.target.value
    });

    if (e.target.value) {
      this.suggestionList(e.target.value);
    } else {
      console.log("ss");

      this.setState({
        suggestions: [songs => [], artists => [], albums => []],

        displaySearch: false
      });
    }
  }

  suggestionList(key) {
    api
      .postMethod("search", { term: key })
      .then(
        response => {
          if (response.data.success) {
            this.setState({
              suggestions: response.data.data,

              displaySearch: true
            });
          } else {
            // Language not yet updated

            console.log(response.data.error);
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed

      this.setState({
        suggestions: [songs => [], artists => [], albums => []],

        displaySearch: false
      });

      $("#search_input").val("");
    }
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        suggestions: [songs => [], artists => [], albums => []],

        displaySearch: false
      });

      $("#search_input").val("");
    }
  }

  componentDidMount() {
    const headerHeight = this.divElement.clientHeight;

    this.setState({ headerHeight });

    if (this.state.isAuthenticated) {
      this.userProfile();
    }

    this.eventEmitter.on("logout_fn", e => {
      this.logout();
    });

    document.addEventListener("keydown", this.escFunction, false);

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);

    document.removeEventListener("mousedown", this.handleClickOutside);

    this.eventEmitter.removeEventListener("logout_fn", function(even, data) {});
  }

  toggleView() {
    this.eventEmitter.emit("open_toggleview");
  }

  render() {
    var heightheader = {
      height: this.state.headerHeight
    };

    const {
      isAuthenticated,
      user_details,
      redirect,
      language_redirect,
      search_redirect,
      search_key,
      suggestions,
      displaySearch
    } = this.state;

    if (redirect) {
      return <Redirect to={"/"} />;
    }

    if (language_redirect) {
      return <Redirect to={"/language"} />;
    }

    if (search_redirect) {
      return <Redirect to={`/search-result/${search_key}`} />;
    }
    return (
      <div>
        <nav
          className="navbar navbar-expand navbar-dark bg-dark"
          id="header"
          ref={divElement => (this.divElement = divElement)}
        >
          <span className="navbar-brand" onClick={this.toggleView.bind(this)}>
            <img
              src="../../assets/icons/menu-white.png"
              className="menu-icon"
              alt="Menu Icon"
            />
          </span>
          <div className="collapse navbar-collapse">
            <form className="form-inline mr-auto" onSubmit={this.searchResult}>
              <div className="input-group search-input">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img
                      src="../../assets/icons/search-gold.png"
                      className="search-img"
                      alt="Search"
                    />
                  </span>
                </div>
                <div className="relative" ref={this.setWrapperRef}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search"
                    onChange={this.handleChange}
                    id="search_input"
                  />
                  {displaySearch && <SearchMenu data={suggestions} />}
                </div>
              </div>
            </form>

            {!isAuthenticated ? (
              <ul className="navbar-nav my-2 my-md-0">
                <li className="nav-item">
                  <Link
                    className="nav-link hidden-mobile"
                    to="#"
                    data-toggle="modal"
                    data-target="#login"
                  >
                    Login
                  </Link>
                  <Link
                    className="nav-link visible-mobile p-0"
                    to="#"
                    data-toggle="modal"
                    data-target="#login"
                  >
                    <img
                      src="../../assets/icons/login-white.png"
                      className="login-icon"
                      alt="Login"
                    />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link hidden-mobile"
                    to="#"
                    data-toggle="modal"
                    data-target="#register"
                  >
                    Register
                  </Link>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav my-2 my-md-0">
                <li className="nav-item relative">
                  <Link
                    className="nav-link p-0 dropdown-toggle"
                    to="#"
                    id="navbardrop"
                    data-toggle="dropdown"
                  >
                    <span className="middle ml-3 hidden-mobile">
                      {user_details.name}
                    </span>
                    <img
                      src={user_details.picture}
                      onError={e => {
                        e.target.onError = "";
                        e.target.src = "../../assets/img/user-placeholder.png";
                      }}
                      className="nav-user-img"
                      alt={user_details.name}
                    />
                  </Link>
                  <div className="dropdown-menu">
                    <Link className="dropdown-item" to={"/profile"}>
                      profile
                    </Link>
                    <Link className="dropdown-item" to={"/card"}>
                      My Cards
                    </Link>
                    <Link className="dropdown-item" to={"/myplans"}>
                      My subscription
                    </Link>
                    <Link
                      className="dropdown-item"
                      to={`/language/${isAuthenticated ? 1 : 0}`}
                    >
                      language
                    </Link>
                    <Link className="dropdown-item" to={"/favorites"}>
                      Favorites
                    </Link>
                    <Link className="dropdown-item" to={"/recent-activities"}>
                      My History
                    </Link>
                    {user_details.login_by === apiConstants.LOGIN_BY && (
                      <Link className="dropdown-item" to={"/change-password"}>
                        Change password
                      </Link>
                    )}
                    <Link className="dropdown-item" to={"/delete-account"}>
                      Delete Acccount
                    </Link>
                    <Link
                      className="dropdown-item"
                      to={"/"}
                      onClick={this.logout}
                    >
                      logout
                    </Link>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </nav>
        <div style={heightheader} />
      </div>
    );
  }
}

export default Header;
