import React, { Component } from "react";

import Slider from "react-slick";

import { Link } from "react-router-dom";

import api from "../../Environment";

const $ = window.$;

class Home extends Component {
  constructor(props) {
    super(props);

    this.divElement = React.createRef();

    this.container = React.createRef();

    this.state = {
      artistHeight: 107,

      isLoaded: false,

      first_section_items: [],

      banner_length: 0,

      recent_activities_length: 0,

      trending_length: 0,

      second_section_items: [],

      favorite_length: 0,

      artists_length: 0,

      categories_length: 0,

      third_section_items: [],

      albums_length: 0
    };

    this.eventEmitter = this.props.eventEmitter;

    this.addQueue = this.addQueue.bind(this);
  }

  addQueue(e, object) {
    e.preventDefault();

    var queue_songs = [];

    queue_songs = localStorage.getItem("queue_songs")
      ? JSON.parse(localStorage.getItem("queue_songs"))
      : [];

    let already_value_exists = 0;

    if (queue_songs.length > 0) {
      if (queue_songs.some(item => item.song_id === object.song_id)) {
        already_value_exists = 1;
      }
    }

    if (!already_value_exists) {
      this.eventEmitter.emit("queue_listen", object);

      $.toast({
        heading: "Success",
        text: "Added to queue",
        icon: "success",
        position: "top-right",
        stack: false,
        textAlign: "left",
        loader: false,
        showHideTransition: "slide"
      });
    } else {
      $.toast({
        heading: "Error",
        text: "Already Added to the queue",
        icon: "error",
        position: "top-right",
        stack: false,
        textAlign: "left",
        loader: false,
        showHideTransition: "slide"
      });
    }
  }

  homePageFirstSection() {
    api
      .postMethod("home/first/list", {})
      .then(
        response => {
          if (response.data.success) {
            var datas = response.data.data;

            this.setState({
              first_section_items: response.data.data,

              isLoaded: true,

              banner_length: datas.banner.data.length,

              recent_activities_length: datas["recent activities"].data.length,

              trending_length: datas.trending.data.length
            });

            this.homePageSecondSection();
          } else {
            console.log(response.data.error);
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  homePageSecondSection() {
    api
      .postMethod("home/second/list", {})
      .then(
        response => {
          if (response.data.success) {
            var datas = response.data.data;

            this.setState({
              second_section_items: response.data.data,

              isLoaded: true,

              favorite_length: datas.favorite.data.length,

              artists_length: datas.artists.data.length,

              categories_length: datas.categories.data.length
            });

            this.homePageThirdSection();
          } else {
            console.log(response.data.error);
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  homePageThirdSection() {
    api
      .postMethod("home/third/list", {})
      .then(
        response => {
          if (response.data.success) {
            var datas = response.data.data;

            this.setState({
              third_section_items: response.data.data,

              isLoaded: true,

              albums_length: datas.albums.data.length
            });
          } else {
            console.log(response.data.error);
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  componentDidMount() {
    var artistHeight;

    setTimeout(() => {
      artistHeight = $(".categoryClass").width();

      console.log($(".artistClass").width());

      this.setState({ artistHeight });
    }, 2 * 1000);

    this.homePageFirstSection();
  }

  render() {
    var heightartist = {
      height: this.state.artistHeight
    };

    var recent = {
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    var trending = {
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };

    var wishlist = {
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };

    var artist = {
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };

    var category = {
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };

    var albums = {
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    const {
      first_section_items,
      isLoaded,
      banner_length,
      recent_activities_length,
      trending_length,
      second_section_items,
      favorite_length,
      artists_length,
      categories_length,
      third_section_items,
      albums_length
    } = this.state;

    if (!isLoaded) {
      // ref={ (divElement) => this.divElement = divElement}
      return (
        <div className="body-content">
          <div className="pl-3 pr-3">
            <div className="">
              <div className="section-loader">
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="body-content">
          <div className="pl-3 pr-3">
            {banner_length <= 0 &&
              recent_activities_length <= 0 &&
              trending_length <= 0 &&
              favorite_length <= 0 &&
              artists_length <= 0 &&
              categories_length <= 0 &&
              albums_length <= 0 && (
                <div className="no-resultsec">
                  <img
                    src="../../assets/img/no-result.png"
                    className="no-result-img"
                    alt="no-result"
                  />
                  <h4>Songs is empty</h4>
                  <p>No results found</p>
                </div>
              )}

            {banner_length > 0 && (
              <div id="demo" className="carousel slide" data-ride="carousel">
                <ul className="carousel-indicators">
                  {first_section_items.banner.data.map((banner_item, index) => (
                    <li
                      data-target="#demo"
                      key={index}
                      data-slide-to={index}
                      className={index === 0 ? "active" : ""}
                    />
                  ))}
                </ul>
                <div className="carousel-inner">
                  {first_section_items.banner.data.map((banner_item, index) => (
                    <div
                      className={
                        index === 0 ? "carousel-item active" : "carousel-item"
                      }
                      key={index}
                    >
                      <Link to={`/music/${banner_item.song_id}`}>
                        <img
                          src={banner_item.picture}
                          onError={e => {
                            e.target.onError = "";
                            e.target.src = "../../assets/img/movie1.jpg";
                          }}
                          alt={banner_item.title}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
                <a
                  className="carousel-control-prev"
                  href="#demo"
                  data-slide="prev"
                >
                  <span className="carousel-control-prev-icon" />
                </a>
                <a
                  className="carousel-control-next"
                  href="#demo"
                  data-slide="next"
                >
                  <span className="carousel-control-next-icon" />
                </a>
              </div>
            )}

            {recent_activities_length > 0 && (
              <div className="music-section">
                <h2>
                  recent activity{" "}
                  <Link to={"/recent-activities"} className="see-all">
                    <span>see all</span>
                  </Link>
                </h2>
                <Slider className="recent-activity slider" {...recent}>
                  {first_section_items["recent activities"].data.map(
                    (recent_item, index) => (
                      <div
                        key={index}
                        onClick={e => this.addQueue(e, recent_item)}
                        className="cursor"
                      >
                        <div className="relative">
                          <img
                            src={recent_item.picture}
                            onError={e => {
                              e.target.onError = "";
                              e.target.src = "../../assets/img/movie1.jpg";
                            }}
                            alt={recent_item.name}
                          />
                          <div className="recent-overlay">
                            <h4>{recent_item.title}</h4>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Slider>
              </div>
            )}

            {trending_length > 0 && (
              <div className="music-section">
                <h2>
                  trending{" "}
                  <Link to={"/trending"} className="see-all">
                    <span>see all</span>
                  </Link>
                </h2>
                <Slider className="trending slider" {...trending}>
                  {first_section_items.trending.data.map(
                    (trending_item, index) => (
                      <div
                        key={index}
                        onClick={e => this.addQueue(e, trending_item)}
                        className="cursor"
                      >
                        <div className="relative">
                          <img
                            src={trending_item.picture}
                            onError={e => {
                              e.target.onError = "";
                              e.target.src = "../../assets/icons/playicon.png";
                            }}
                            alt={trending_item.title}
                          />
                          <div className="trending-overlay">
                            <img
                              src="../../assets/icons/playicon.png"
                              className="playicon"
                              alt="playicon"
                            />
                          </div>
                        </div>
                        <h4 className="song-title">{trending_item.title}</h4>
                      </div>
                    )
                  )}
                </Slider>
              </div>
            )}

            {favorite_length > 0 && (
              <div className="music-section">
                <h2>
                  Favorite{" "}
                  <Link to={"/favorites"} className="see-all">
                    <span>see all</span>
                  </Link>
                </h2>
                <Slider className="wishlist slider" {...wishlist}>
                  {second_section_items.favorite.data.map(
                    (favorite_item, index) => (
                      <div key={index}>
                        <Link to={"/"}>
                          <div>
                            <div className="relative">
                              <img
                                src={favorite_item.picture}
                                onError={e => {
                                  e.target.onError = "";
                                  e.target.src = "../../assets/img/song1.jpg";
                                }}
                                alt={favorite_item.title}
                              />
                              <div className="wishlist-overlay">
                                <img
                                  src="../../assets/icons/playicon.png"
                                  className="playicon"
                                  alt="playicon"
                                />
                              </div>
                            </div>
                            <h4 className="song-title">{favorite_item.name}</h4>
                          </div>
                        </Link>
                      </div>
                    )
                  )}
                </Slider>
              </div>
            )}

            {artists_length > 0 && (
              <div className="music-section">
                <h2>
                  artist{" "}
                  <Link to={"/artists"} className="see-all">
                    <span>see all</span>
                  </Link>
                </h2>
                <Slider
                  className="artist slider"
                  {...artist}
                  ref={el => (this.container = el)}
                >
                  {second_section_items.artists.data.map(
                    (artist_item, index) => (
                      <div key={index} className="artistClass">
                        <Link to={`/artist-view/${artist_item.artist_id}`}>
                          <img
                            src={artist_item.picture}
                            onError={e => {
                              e.target.onError = "";
                              e.target.src = "../../assets/img/artist1.jpg";
                            }}
                            alt={artist_item.name}
                            style={heightartist}
                          />
                          <h4 className="artist-name">{artist_item.name}</h4>
                        </Link>
                      </div>
                    )
                  )}
                </Slider>
              </div>
            )}

            {categories_length > 0 && (
              <div className="music-section">
                <h2>
                  top category{" "}
                  <Link to={"/categories"} className="see-all">
                    <span>see all</span>
                  </Link>
                </h2>
                <Slider className="category slider" {...category}>
                  {second_section_items.categories.data.map(
                    (category_item, index) => (
                      <div className="categoryClass" key={index}>
                        <Link
                          to={`/category-view/${category_item.category_id}`}
                        >
                          <img
                            src={category_item.picture}
                            style={heightartist}
                            onError={e => {
                              e.target.onError = "";
                              e.target.src =
                                "../../assets/img/cate-grunge-music.jpg";
                            }}
                            className="category-img"
                            alt={category_item.name}
                          />
                        </Link>
                      </div>
                    )
                  )}
                </Slider>
              </div>
            )}

            {albums_length > 0 && (
              <div className="music-section">
                <h2>
                  albums{" "}
                  <Link to={"/albums"} className="see-all">
                    <span>see all</span>
                  </Link>
                </h2>
                <Slider className="albums slider" {...albums}>
                  {third_section_items.albums.data.map((album_item, index) => (
                    <div key={index}>
                      <Link to={`/album-view/${album_item.movie_album_id}`}>
                        <div className="albums-sec">
                          <div className="relative">
                            <img
                              src={album_item.picture}
                              onError={e => {
                                e.target.onError = "";
                                e.target.src = "../../assets/img/song1.jpg";
                              }}
                              alt={album_item.name}
                            />
                            <div className="trending-overlay">
                              <img
                                src="assets/icons/playicon.png"
                                className="playicon"
                                alt="playicon"
                              />
                            </div>
                          </div>
                          <div className="albums-details">
                            <h4 className="title">{album_item.name}</h4>
                            <h5 className="name">{album_item.description}</h5>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

export default Home;
