import React, {Component} from 'react';

import {StripeProvider, Elements} from 'react-stripe-elements';

import CheckoutForm from './CheckoutForm';

import api from '../../Environment';

import { Redirect, Link } from 'react-router-dom';

const $ = window.$;

class Card extends Component {

    constructor(props) {

        super(props);

        const {match : {params}} = this.props;

        const redirection = params.is_subscription > 0 ? 1 : 0;

        localStorage.setItem('is_subscription', redirection);

        this.state = {

            stripe_public_key : "pk_test_uDYrTXzzAuGRwDYtu7dkhaF3",

            card_items : [],

            redirect : false

        };

        this.defaultCard = this.defaultCard.bind(this);

        this.deleteCard = this.deleteCard.bind(this);

    }


    componentDidMount() {

        this.cardItems();
        
    }   

    defaultCard(e, id) {

        e.preventDefault();


        api.postMethod("cards_default", {user_card_id : id})
        .then(
            (response) => {
    
                if (response.data.success) {                    
    
                    //this.cardItems();

                    $.toast({
                        heading: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                    setTimeout(()=>{

                        window.location.reload(); 
    
                    }, 1000);
    
                } else {
    
                    $.toast({
                        heading: 'Error',
                        text: response.data.error,
                        icon: 'error',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });
    
                }
    
            },
    
            (error) => {
    
                console.log(error);
    
    
            },
        )
        .catch(
            (err) => {
    
                console.log("oops something went wrong");
    
            }
        );

    }

    deleteCard(e, id) {

        e.preventDefault();

        api.postMethod("cards_delete", {user_card_id : id})
        .then(
            (response) => {
    
                if (response.data.success) {                    
    

                    $.toast({
                        heading: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                    setTimeout(()=>{

                        window.location.reload(); 
    
                    }, 1000);
    
                } else {
    
                    $.toast({
                        heading: 'Error',
                        text: response.data.error,
                        icon: 'error',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });
    
                }
    
            },
    
            (error) => {
    
                console.log(error);
    
    
            },
        )
        .catch(
            (err) => {
    
                console.log("oops something went wrong");
    
            }
        );

    }




    setDefaultCard(e, id) {

        e.preventDefault();

        api.postMethod("cards_default", {user_card_id : id})
        .then(
            (response) => {
    
                if (response.data.success) {                    
    

                    $.toast({
                        heading: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                    setTimeout(()=>{

                        window.location.reload(); 
    
                    }, 1000);
    
                } else {
    
                    $.toast({
                        heading: 'Error',
                        text: response.data.error,
                        icon: 'error',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });
    
                }
    
            },
    
            (error) => {
    
                console.log(error);
    
    
            },
        )
        .catch(
            (err) => {
    
                console.log("oops something went wrong");
    
            }
        );

    }

    cardItems() {

        api.postMethod("cards_list", {})
        .then(
            (response) => {
    
                if (response.data.success) {
    
                    this.setState({

                        card_items : response.data.data.cards

                    });
                    
    
                } else {
    
                    console.log(response.data.error);
    
                }
    
            },
    
            (error) => {
    
                console.log(error);
    
    
            },
        )
        .catch(
            (err) => {
    
                console.log("oops something went wrong");
    
            }
        );
    
    }

    render() {

        const {stripe_public_key, card_items, redirect} = this.state;

       if(redirect) {

           return <Redirect to={'/card'} />;

       }
        
        return (
            <div className="body">
                <div className="section-spacing">
                    <div className="row">
                        <div className="col-sm-12 col-md-10 offset-md-1 offset-lg-0 col-lg-12 col-xl-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6">
                                    <div className="music-section">
                                        <h2>Add card</h2>
                                    </div>

                                    <StripeProvider apiKey={stripe_public_key}>
                                        <Elements>
                                            <CheckoutForm/>
                                        </Elements>
                                    </StripeProvider>
                                    
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6">
                                    <div className="music-section">
                                        <h2>card list</h2>
                                    </div>
                                    {card_items.length <= 0 &&
                                        <div className="no-resultsec">
                                            <img src="../../assets/img/no-result.png" className="no-result-img" alt="no-result"  />
                                            <h4>Cards is empty</h4>
                                            <p>Add your card details here </p>
                                        </div>
                                    }
                                    { card_items.length > 0 && card_items.map((item,index)=> (
                                        <div key={index}>
                                            <div className="display-inline">
                                                <div className="card-sec">
                                                
                                                    {item.is_default 
                                                        ? (<input type="radio" id={`radio${index}`} name="radio-category" defaultChecked/>)
                                                        : (<input type="radio" id={`radio${index}`} name="radio-category" onClick={ e => this.defaultCard(e, item.user_card_id)}/>) }
                                                    <label htmlFor={`radio${index}`} className="m-0">
                                                        <img src="../../assets/icons/credit-card.png" alt="card" className="card-img" />
                                                    </label>
                                                </div>
                                                <div className="card-details-sec">
                                                    <h4>XXXX XXXX XXXX {item.last_four}</h4>
                                                    <a class="green-clr">
                                                        {item.is_default == 1 ? 'Default Card' : 
                                                        
                                                        <Link className="text-info"
                                                        onClick={(event) =>
                                                        this.setDefaultCard(event, item.user_card_id)
                                                        }
                                                        to={""}
                                                    >
                                                        Set as Default
                                                    </Link>
                                                        }
                                                    </a>
                                                </div>
                                                <div className="delete-sec">
                                                    <Link to={'#'}  onClick={ e => this.deleteCard(e, item.user_card_id)}><img src="../../assets/icons/delete-gold.png" alt="delete" className="delete-img"/></Link>
                                                </div>
                                            </div>
                                            <p className="card-line"></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>	
                </div>
            </div>

        );

    }

}

export default Card;