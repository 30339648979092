import React, {Component} from 'react';

import Slider from "react-slick";

import api from '../../Environment';

import { Link } from 'react-router-dom';

import Background from '../../assets/img/movie1.jpg';
import configuration from "react-global-configuration";

const $ = window.$;

var sectionStyle = {
    backgroundImage: `url(${Background})`
};

class SongView extends Component{

    constructor(props) {

        super(props);

        this.eventEmitter = this.props.eventEmitter;

        this.closePage = this.closePage.bind(this);

        var queueSongsList = localStorage.getItem('queue_songs') ? JSON.parse(localStorage.getItem('queue_songs')) : [];

        
        this.state = {

            queue_songs : queueSongsList,

            isAuthenticated : this.props.isAuthenticated
        }


        this.wishlistOperation = this.wishlistOperation.bind(this);

    }

    componentDidMount() {

        this.eventEmitter.on("open_songview", (e) => {

           $("#single-music-page").css('display', 'block');

           $("#single-music-page").addClass("active");

        });
        
        this.queueSongs();
    }

    wishlistOperation(e, song_id, index) {

        e.preventDefault();

        var currentTarget = e.target;

        api.postMethod("wishlist/operations", {song_id : song_id})
            .then(

                (response)=> {

                    if (response.data.success) {

                        currentTarget.src = response.data.wishlist_id > 0 ? '../../assets/icons/wishlist-gold.png' : '../../assets/icons/wishlist-white.png'

                        var update_queue_songs = localStorage.getItem('queue_songs') ? JSON.parse(localStorage.getItem('queue_songs')) : [];

                        if (update_queue_songs) {
                            update_queue_songs[index].wishlist_status = response.data.wishlist_id > 0 ? 1 : 0;
                        }

                        localStorage.setItem('queue_songs', JSON.stringify(update_queue_songs));


                    } else {

                        $.toast({
                            heading: 'Error',
                            text: response.data.error,
                            icon: 'error',
                            position: 'top-right',
                            stack: false,
                            textAlign: 'left',
                            loader : false,
                            showHideTransition: 'slide'
                        });

                    }

                },
                (error) => {

                    console.log(error);



                },
            )
            .catch(
                (err) => {

                    console.log("oops something went wrong");

                }
            );

    }

    queueSongs() {


        this.eventEmitter.on("slider_queue_listen", (e)=>{

            var queue_songs = localStorage.getItem('queue_songs') ? JSON.parse(localStorage.getItem('queue_songs')) : [];
            
            this.setState({
    
                queue_songs : queue_songs
    
            })

        });

             
    }

    componentWillUnmount() {

        this.eventEmitter.removeEventListener("slider_queue_listen", (event)=>{

            console.log("unmount");

        })


    }
    closePage(e) {

        e.preventDefault();

        $("#single-music-page").css('display', 'none');

    }

    render() {

        const {queue_songs, isAuthenticated} = this.state;

        var music = {
            infinite:true,
            centerMode: true,
            centerPadding: '30%',
            slidesToShow: 1,
            speed: 500,
            responsive: [
                {
                    breakpoint: 576,
                        settings: {
                        centerPadding: '10%',
                    }
                }
            ]
        };

        return (

            <div id="single-music-page" className="songview-cls">
                <div className="single-music-bg" style={sectionStyle}>
                </div>
                <div className="single-music-overlay">
                    <div className="music-header">
                        <img src={configuration.get("configData.site_logo")} className="site-logo m-0" alt="logo" />
                        <img src="../../assets/icons/close-white.png" className="close-btn" id="close-btn" alt="close" onClick={this.closePage}/>
                        { /*<img src="../../assets/icons/info-white.png" data-toggle="modal" data-target="#song-info" className="close-btn mr-3" alt="info" />*/}
                    </div>
                    <div className="music-content">
                        <div className="music-width">
                            <Slider className="music-slider slider" {...music}>
                                
                                    {queue_songs.length > 0 && queue_songs.map((item,index)=>(
                                        <div key={`song_view${index}`}>
                                            <div className="padd-2">
                                                <h4 className="slider-ellipsis">{item.title}</h4>
                                                <h5 className="slider-ellipsis">{item.description}</h5>
                                                <div className="pt-4 pb-4">
                                                    <img src={item.picture} onError={(e)=>{e.target.onError='';e.target.src="../../assets/img/movie1.jpg"}} alt={item.title} />
                                                </div>
                                                <div className="single-page-actions">
                                                    <ul>
                                                     {isAuthenticated &&
                                                        <li className="">
                                                            {/* @Shobana 
                                                            Add class if it is added to wishlist- "added-wishlist" */}
                                                            {item.wishlist_status > 0 
                                                            ? (<img src="../../assets/icons/wishlist-gold.png" className="default-img" alt="wishlist-added" onClick={e => this.wishlistOperation(e,item.song_id, index)}/>)
                                                            : (<img src="../../assets/icons/wishlist-white.png" className="default-img" alt="wishlist" onClick={e => this.wishlistOperation(e,item.song_id, index)}/>) 
                                                            }
                                                        </li>
                                                    }
                                                        <li className="">
                                                            <img src="../../assets/icons/share-symbol-white.png" className="default-img" alt="download"/>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                            </Slider>

                        </div>
                    </div>
                </div>
                <div className="modal fade" id="song-info">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Song info</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="display-inline">
                                    <div className="song-info-left">
                                        <img src="../../assets/img/movie1.jpg" alt="movie-img" />
                                    </div>
                                    <div className="song-info-right">
                                        <h4 className="capitalize">kisna Theme -Instrumental (Flute) (Instrumental)</h4>
                                        <h5 className="capitalize">Raabta</h5>
                                    </div>
                                </div>
                                <p className="song-info-line"></p>
                                <div>
                                    <h4 className="mt-0 capitalize">artist</h4>
                                    <div className="row">
                                        <div className="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-center">
                                            <Link to="">
                                                <img src="../../assets/img/artist1.jpg" className="songinfo-grid-img" alt="artist_img" />
                                                <h4 className="artist-grid-name">amar diab</h4>   	
                                            </Link>
                                        </div>
                                        <div className="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-center">
                                            <Link to="">
                                                <img src="../../assets/img/artist1.jpg" className="songinfo-grid-img" alt="artist_img" />
                                                <h4 className="artist-grid-name">amar diab</h4>   	
                                            </Link>
                                        </div>
                                        <div className="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-center">
                                            <Link to="">
                                                <img src="../../assets/img/artist1.jpg" className="songinfo-grid-img" alt="artist_img" />
                                                <h4 className="artist-grid-name">amar diab</h4>   	
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <button type="button" className="btn btn-warning btn-solid" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
		    </div>

        
        );

    }

}

export default SongView;