import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import api from '../../Environment';

class Artist extends Component {

    constructor(props) {

        super(props);

        const {match : {params}} = this.props;

        this.state = {

            error_messages : '',

            isLoaded : false,

            items : [],

            sectionLoader : false,

            skipCount : 0,

            search_key : (params.key !==undefined && params.key !== null && params.key !== '') ? params.key : ''
        };

        this.showMore = this.showMore.bind(this);
    }

    showMore(e) {

        this.artistList(this.state.skipCount);

    }

    artistList(skip) {

        if (this.state.skipCount > 0) {

            this.setState({

                sectionLoader : true

            })

        }

        var url = "artists/list";

        var object = {skip:skip};

        if (this.state.search_key) {

            url = "search/artists";

            object = {skip:skip, term : this.state.search_key, type : 'artists'};
        }

        api.postMethod(url, object)
        .then(
            (response) => {

                if (response.data.success) {

                    if (this.state.skipCount > 0) {

                        var items = [...this.state.items, ...response.data.data];

                        this.setState({

                            isLoaded : true,

                            items : items,

                            skipCount : this.state.skipCount+response.data.data.length,

                            sectionLoader : false

                        });

                    } else {

                        this.setState({

                            isLoaded : true,

                            items : response.data.data,

                            no_of_songs : response.data.no_of_songs,

                            skipCount : response.data.data.length

                        });
                    }

                } else {

                    this.setState({

                        isLoaded : true,

                        error_messages : response.data.error

                    });

                }

            },

            (error) => {

                console.log(error);

                this.setState({

                    isLoaded : true,

                    error_messages : error
                    
                });


            },
        )
        .catch(
            (err) => {

                this.setState({

                    isLoaded : true,

                    error_messages : "oops something went wrong"
                    
                });

            }
        );

    }

    componentDidMount() {

       this.artistList(0);

    }
    render() {

        const {error_messages, isLoaded, items, sectionLoader} = this.state;

        if (error_messages) {

            return (<div className="body-content">Error : {error_messages}</div>)

        } else if (!isLoaded) {

            return (<div className="body-content">
                <div className="pl-3 pr-3">
                    <div className="section-loader">
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                    </div>
                </div>
            </div>)

        } else {

            return (
                <div className="body-content">
                    <div className="music-section">
                        <h2>artist</h2>

                        {items.length <= 0 &&
                            <div className="no-resultsec">
                                <img src="../../assets/img/no-result.png" className="no-result-img" alt="no-result"  />
                                <h4>Artists is empty</h4>
                                <p>No artist added here</p>
                            </div>
                        }
                        
                        <div className="row">
                            {items.length > 0 && items.map((item,index)=>(
                                <div key={item.artist_id} className="col-6 col-sm-4 col-md-4 col-lg-6 col-xl-4 text-center">
                                    <Link to={`/artist-view/${item.artist_id}`}>
                                        <img src={item.picture} onError={(e)=> {e.target.onError = ''; e.target.src="../../assets/img/artist1.jpg"}} className="artist-grid-img" alt={item.name} />
                                        <h4 className="artist-grid-name">{item.name}</h4>   	
                                    </Link>
                                </div>
                            ))}
                        </div>
                        
                        {
                            sectionLoader && 
                            <div className="pl-3 pr-3">
                                <div className="section-loader">
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                </div>
                            </div>

                        }

                        {
                            items.length > 0 &&
                            <div className="text-right">
                                <button className="btn btn-solid btn-warning mr-0" onClick={e => this.showMore(e)}>show more</button>
                            </div>
                        }

                    </div>
                </div>

            );

        }

    }

}

export default Artist;