import React, { Component } from "react";

import { Link } from "react-router-dom";

import api from "../../Environment";

import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import { reducer as jPlayers } from "react-jplayer";
import { reducer as jPlaylists } from "react-jplaylist";

// Styles the jPlaylist to look nice

import "../../../node_modules/react-jplaylist/dist/css/skins/sleek.min.css";

import "../../../node_modules/react-jplaylist/dist/css/controls/iconControls.min.css";

// import '../../../node_modules/react-jplayer-utils/dist/react-jPlayer-utils.min.js';

// import '../../../node_modules/react-jplayer/dist/js/react-jPlayer.min.js';

// import '../../../node_modules/react-jplaylist/dist/js/react-jPlaylist.min.js';

import AudioPlaylist from "./AudioPlaylist";
import JinkeMusicPlayer from "./AudioPlayerNew";

/* Pass the jPlaylist reducer and it's initialStates to the store */
const store = createStore(combineReducers({ jPlayers, jPlaylists }));

const $ = window.$;

class RightSidebar extends Component {
  constructor(props) {
    super(props);

    this.eventEmitter = this.props.eventEmitter;

    this.state = {
      isAuthenticated: this.props.data,

      queue_songs: []
    };

    this.wishlistOperation = this.wishlistOperation.bind(this);

    this.removeFromQueue = this.removeFromQueue.bind(this);

    this.queueClear = this.queueClear.bind(this);
  }

  queueSongs() {
    var queue_songs = localStorage.getItem("queue_songs")
      ? JSON.parse(localStorage.getItem("queue_songs"))
      : [];

    this.setState({
      queue_songs: queue_songs
    });

    this.eventEmitter.on("queue_listen", e => {
      var queue = [];

      queue = localStorage.getItem("queue_songs")
        ? JSON.parse(localStorage.getItem("queue_songs"))
        : [];

      let already_value_exists = 0;

      if (queue.length > 0) {
        if (queue.some(item => item.song_id === e.song_id)) {
          already_value_exists = 1;

          return false;
        }
      }

      if (!already_value_exists) {
        queue.push(e);

        queue = JSON.stringify(queue);

        localStorage.setItem("queue_songs", queue);

        var queue_songs = localStorage.getItem("queue_songs")
          ? JSON.parse(localStorage.getItem("queue_songs"))
          : [];

        this.setState({
          queue_songs: queue_songs
        });

        this.eventEmitter.emit("slider_queue_listen", true);
      }
    });
  }

  removeFromQueue(e, song_id) {
    e.preventDefault();

    var queue_songs = localStorage.getItem("queue_songs")
      ? JSON.parse(localStorage.getItem("queue_songs"))
      : [];

    if (queue_songs.length > 0) {
      queue_songs.some((item, index) => {
        if (item.song_id === song_id) {
          $.toast({
            heading: "Success",
            text: item.title + " Song has been removed from queue",
            icon: "success",
            position: "top-right",
            stack: false,
            textAlign: "left",
            loader: false,
            showHideTransition: "slide"
          });

          queue_songs.splice(index, 1);

          localStorage.setItem("queue_songs", JSON.stringify(queue_songs));

          queue_songs = localStorage.getItem("queue_songs")
            ? JSON.parse(localStorage.getItem("queue_songs"))
            : [];

          this.setState({
            queue_songs: queue_songs
          });
        }
      });
    }
  }

  queueClear(e) {
    e.preventDefault();

    localStorage.removeItem("queue_songs");

    this.setState({
      queue_songs: []
    });
  }

  componentWillUnmount() {
    this.eventEmitter.removeEventListener("queue_listen", event => {
      console.log("unmount");
    });

    this.eventEmitter.removeEventListener("remove_queue_listen", e => {
      console.log("unmount");
    });
  }

  wishlistOperation(e, song_id) {
    e.preventDefault();

    var currentTarget = e.target;

    api
      .postMethod("wishlist/operations", { song_id: song_id })
      .then(
        response => {
          if (response.data.success) {
            currentTarget.textContent =
              response.data.wishlist_id > 0
                ? "remove from Favorite"
                : "add to Favorite";
          } else {
            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  componentDidMount() {
    this.queueSongs();

    this.eventEmitter.on("remove_queue_listen", e => {
      this.queueSongs();
    });

    this.eventEmitter.on("open_queue", e => {
      $(".queue-sec").css("display", "block");
    });
  }

  closeQueue() {
    $(".queue-sec").css("display", "none");
  }

  handleClickSong = queue_item => {
    console.log("Click tiggered");
    this.setState({ queue_songs: [queue_item] });
    this.render();
  };

  render() {
    const { queue_songs, isAuthenticated } = this.state;
    console.log("Render");

    return (
      <div>
        {/* <div className="queue-sec">
          <div className="queue-title">
            <h4 className="pull-left mt-1">queue</h4>
            <button
              className="pull-right btn btn-outline btn-warning m-0 ml-1 small-btn queue-close-btn"
              onClick={this.closeQueue}
            >
              close
            </button>
            {queue_songs.length > 0 && (
              <button
                className="pull-right btn btn-solid btn-warning m-0 small-btn"
                onClick={this.queueClear}
              >
                clear
              </button>
            )}
            <div className="clearfix" />
          </div>
          <div className="queue-list">
            {queue_songs.length > 0 &&
              queue_songs.map((queue_item, index) => (
                <div key={`queue_${index}`}>
                  <div className="display-inline song-list">
                    <Link
                      to=""
                      onClick={() => this.handleClickSong(queue_item)}
                    >
                      <div className="queue-sec1">
                        <span>{index + 1}</span>
                        <div className="relative inline">
                          <img
                            src={queue_item.picture}
                            onError={e => {
                              e.target.onError = "";
                              e.target.src = "../../assets/img/song1.jpg";
                            }}
                            className="song-img"
                            alt={queue_item.title}
                          />
                          <img
                            src="../../assets/icons/playicon1.png"
                            className="song-play-icon"
                            alt="play_icon"
                          />
                        </div>
                      </div>
                      <div className="queue-sec2">
                        <h4>{queue_item.title}</h4>
                        <h5>{queue_item.description}</h5>
                      </div>
                    </Link>
                    <div className="queue-sec3">
                      <Link
                        to="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <img
                          src="../../assets/icons/more-horizondal-grey.png"
                          alt="download-arrow"
                        />
                      </Link>
                      <div className="dropdown-menu">
                        <div
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#sharebtn"
                          onClick={e =>
                            this.eventEmitter.emit("share_details", queue_item)
                          }
                        >
                          share
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={e =>
                            this.removeFromQueue(e, queue_item.song_id)
                          }
                        >
                          remove form queue
                        </div>
                        {isAuthenticated && (
                          <div
                            className="dropdown-item"
                            onClick={e =>
                              this.wishlistOperation(e, queue_item.song_id)
                            }
                          >
                            {queue_item.wishlist_status > 0
                              ? "remove from Favorite"
                              : "add to Favorite"}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="queue-line" />
                </div>
              ))}

            {queue_songs.length <= 0 && (
              <div className="no-resultsec">
                <img
                  src="../../assets/img/no-result.png"
                  className="no-result-img"
                  alt="no-result"
                />
                <h4>your queue is empty</h4>
                <p>start some music and add songs to Queue</p>
              </div>
            )}
          </div>
        </div>

         */}
        {queue_songs.length > 0 && (
          <div className="">
            {queue_songs.length > 0 && (
              // <Provider store={store}>
              //   <AudioPlaylist
              //     songs={queue_songs}
              //     isAuthenticated={isAuthenticated}
              //     eventEmitter={this.eventEmitter}
              //   />
              // </Provider>
              <JinkeMusicPlayer
                songs={queue_songs}
                isAuthenticated={isAuthenticated}
                eventEmitter={this.eventEmitter}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default RightSidebar;
