import React, {Component} from 'react';

import {NavLink} from 'react-router-dom';

class PageSideber extends Component {

    constructor(props) {

        super(props);

        this.state = {

            page_items : this.props.pageItems,

        };


    }

    componentDidMount() {


    }

    render(){

        const {page_items} = this.state;

    
        return(
            <div>
                <ul className="static-links">
                    {page_items.map((item, index) => (
                        <li key={index}>
                            <NavLink to={`/page/${item.page_id}`}>
                                {item.title}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        )
        
    }
}

export default PageSideber;