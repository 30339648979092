import React, { Component } from "react";

import {Link} from  'react-router-dom';

class Songs extends Component {
    render(){
        return(
            <div className="body-content">
                <div className="music-section">
                    <h2>Songs</h2>
                </div>

                <div className="row">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="trending">
                            <Link to="">
                                <div className="relative">
                                    <img src="assets/img/song1.jpg" alt="trending" />
                                    <div className="trending-overlay">
                                        <img src="assets/icons/playicon.png" className="playicon" alt="playicon" />
                                    </div>
                                </div>
                                <h4 className="song-title">venom music</h4>
                            </Link>
                        </div>
                    </div>

                    <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="trending">
                            <Link to="">
                                <div className="relative">
                                    <img src="assets/img/song2.jpg" alt="trending" />
                                    <div className="trending-overlay">
                                        <img src="assets/icons/playicon.png" className="playicon" alt="playicon" />
                                    </div>
                                </div>
                                <h4 className="song-title">venom music</h4>
                            </Link>
                        </div>
                    </div>

                    <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="trending">
                            <Link to="">
                                <div className="relative">
                                    <img src="assets/img/song3.jpg" alt="trending" />
                                    <div className="trending-overlay">
                                        <img src="assets/icons/playicon.png" className="playicon" alt="playicon" />
                                    </div>
                                </div>
                                <h4 className="song-title">venom music</h4>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
};

export default Songs;