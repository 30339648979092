import React, {Component} from 'react';

import api from '../../Environment';

import {Redirect} from 'react-router-dom';

const $ = window.$;

class EditProfile extends Component {


    constructor(props) {

        super(props);

        this.state = {

            isLoaded : false,

            update_form : {

                name : "",

                picture : null,

                preview_picture : "" ,

                redirect : false

            }

        };

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleChange = this.handleChange.bind(this);

    }

    userProfile() {

        api.postMethod("profile", {})
        .then(

            (response)=> {

                if (response.data.success) {

                    localStorage.setItem('accessToken', response.data.data.token);

                    localStorage.setItem('userId', response.data.data.user_id);

                    this.setState({

                        isLoaded : true,

                        update_form : {

                            name : response.data.data.name,
            
                            preview_picture : response.data.data.picture
            
                        }
                    });

                } else {

                   console.log(response.data.error);

                }

            },
            (error) => {

                console.log(error);


            },
        )
        .catch(
            (err) => {

               console.log("oops something went wrong");

            }
        );

    }

    handleSubmit(event) {

        event.preventDefault();

        if (!event.target.checkValidity()) {

            // form is invalid! so we do nothing

            return;

        }

        // form is valid! We can parse and submit data

        api.postMethod("update_profile", this.state.update_form)
        .then(

            (response)=> {

                if (response.data.success) {

                    localStorage.setItem('accessToken', response.data.data.token);

                    localStorage.setItem('userId', response.data.data.user_id);

                    setTimeout(()=>{

                        this.setState({redirect : true});

                    }, 1000);

                    $.toast({
                        heading: 'Success',
                        text: "Successfully updated your profile",
                        icon: 'success',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });
                    

                } else {

                    $.toast({
                        heading: 'Error',
                        text: response.data.error,
                        icon: 'error',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });
                }

            },
            (error) => {

                console.log(error);


            },
        )
        .catch(
            (err) => {

                console.log("oops something went wrong");

            }
        );

    }

    handleChange(event) {

        const { name, value } = event.target;

        const {update_form} = this.state;

        if (name === 'preview_picture') {

            let reader = new FileReader();

            let file = event.target.files[0];

            if (event.target.files.length > 0) {

                reader.onloadend = () => {

                    this.setState({ 
                        update_form : {...update_form, ...{
                                picture: file,
                                preview_picture: reader.result,
                            }
                        }
                    });  
        
                
                }
            
                reader.readAsDataURL(file);

            }

        } else {

            this.setState({ 
                update_form : {...update_form, [name]: value }
            });

        }


    }

    componentDidMount() {

        this.userProfile();

    }



    render() {

        const {isLoaded, update_form} = this.state;

        if (this.state.redirect) {

            return <Redirect to={'/profile'} />;
            
        }

        if (!isLoaded) {

            return (<div className="body-content">
                <div className="pl-3 pr-3">
                    <div className="section-loader">
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                    </div>
                </div>
            </div>);

        } else {

            return (

                <div className="body-content">
                    <div className="music-section">
                        <h2>edit profile</h2>
                        <form className="auth-form" onSubmit={this.handleSubmit} encType="multipart">
                            <div className="display-inline">
                                <div className="editprofile-image-sec">
                                    <div className="image-upload">
                                        <label htmlFor="file-input">
                                            <img src={update_form.preview_picture} onError={(e)=>{e.target.onError=''; e.target.src="../../assets/img/song3.jpg"}} className="edit-img" alt={update_form.name}/>
                                            <div className="profile-overlay">
                                                <img src="../../assets/icons/camera-gold.png" alt="camera" />
                                            </div>
                                        </label>
                                        <input id="file-input" type="file" name="preview_picture" onChange={this.handleChange} accept="image/jpeg, image/png"/>
                                    </div>
                                </div>
                                <div className="editprofile-text-sec">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <img src="../assets/icons/account-gold.png" className="auth-img" alt="camera"/>
                                            </span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="username" name="name" value={update_form.name} onChange={this.handleChange} maxLength="128" minLength="2"/>
                                    </div>
                                    <div className="">
                                        <button className="btn btn-warning mt-0 btn-solid">update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>					
                </div>

                
            );

        }

    }

}

export default EditProfile;