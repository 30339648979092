import React, {Component} from 'react';

import Header from './Header';

import LeftSidebar from './LeftSidebar';

import SongView from '../Home/SongView';
import Login from '../Home/Login';
import ForgetPassword from '../Home/ForgetPassword';
import Register from '../Home/Register';

class TwoColumnLayout extends Component {

    constructor(props) {

        super(props);

        this.eventEmitter = this.props.screenProps;

        let userId = (localStorage.getItem('userId') !== '' && localStorage.getItem('userId') !== null && localStorage.getItem('userId') !== undefined) ? localStorage.getItem('userId') : '';

        let accessToken = (localStorage.getItem('accessToken') !== '' && localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== undefined) ? localStorage.getItem('accessToken') : '';

        this.state = {
            
            isAuthenticated : userId && accessToken ? true : false

        }

    }

    componentWillUnmount() {



    }

    render() {

        const isAuthenticated = this.state.isAuthenticated;

        return (
            <div className="wrapper">
                <LeftSidebar data={isAuthenticated}/>
                <div className="main-sec">
                    <Header data={isAuthenticated} eventEmitter={this.eventEmitter}/>
                    <div className="header-height"></div>
                    {React.cloneElement(this.props.children, {eventEmitter : this.eventEmitter, data : isAuthenticated})}
                    <div className="body-height"></div>
                </div>

                    <SongView eventEmitter={this.eventEmitter}/>

                    <Login />

                    <ForgetPassword />

                    <Register />
            </div>
        )
    }
}
export default TwoColumnLayout;

