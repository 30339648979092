import React, { Component } from 'react';
import ReactTimeout from 'react-timeout';

import Emitter from './components/Constant/EventEmitter';

import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import createHistory from 'history/createBrowserHistory';

// import routes from './Route'; // Feature Use

import ThreeColumnLayout from './components/Layout/ThreeColumnLayout';
import TwoColumnLayout from './components/Layout/TwoColumnLayout';
import SingleColumnLayout from './components/Layout/SingleColumnLayout';
import Error from './components/Layout/Error';

import Home from './components/Home/Home';
import SingleAudio from './components/Home/SingleAudio';

import Trending from './components/MyList/Trending';
import SubscribedPlan from './components/MyList/SubscribedPlans';
import Download from './components/MyList/Downloads';
import RecentActivity from './components/MyList/RecentActivities';
import Wishlist from './components/MyList/Wishlist';
import PaymentSuccess from './components/MyList/PaymentSuccess';
import PaymentFailure from './components/MyList/PaymentFailure';

import Category from './components/Menus/Category';
import Artist from './components/Menus/Artist';
import Album from './components/Menus/Album';
import Songs from './components/Menus/Songs';
import Subscription from './components/Menus/Subscription';
import Search from './components/Menus/Search';
import CategoryView from './components/Menus/CategoryView';
import ArtistView from './components/Menus/ArtistView';
import AlbumView from './components/Menus/AlbumView';


import Profile from './components/MyAccount/Profile';
import EditProfile from './components/MyAccount/EditProfile';
import ChangePassword from './components/MyAccount/ChangePassword';
import DeleteAccount from './components/MyAccount/DeleteAccount';
import Card from './components/MyAccount/Card';
import Welcome from './components/MyAccount/Welcome';
import Language from './components/MyAccount/Language';

import Page  from "./components/Static/Page";
import configuration from "react-global-configuration";
import { apiConstants } from "./components/Constant/Constants";
import { Helmet } from "react-helmet";

const history = createHistory();
const $ = window.$;

const AppRoute = ({ component: Component, layout: Layout, screenProps : ScreenProps, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout screenProps={ScreenProps}>
      <Component {...props}/>
    </Layout>
  )} />
)


const PrivateRoute = ({component: Component, layout: Layout, screenProps : ScreenProps, authentication, ...rest}) => (
    <Route
      {...rest}
      render={(props) => authentication === true
        ? <Layout screenProps={ScreenProps}><Component {...props}/></Layout>
        : <Redirect to={{pathname: '/home', state: {from: props.location}}} />}
    />
)

class App extends Component {

  constructor(props) {

      super(props);

      let userId = localStorage.getItem('userId');

      let accessToken = localStorage.getItem('accessToken');

      this.state = { loading : true, configLoading: true, authentication : userId && accessToken ? true : false};

      this.eventEmitter = new Emitter();

      history.listen((location, action) => {

        userId = localStorage.getItem('userId');

        accessToken = localStorage.getItem('accessToken');

        this.setState({ loading : true, authentication : userId && accessToken ? true : false});

        // this.loadingFn();

          document.body.scrollTop = 0;
  
      });

      this.fetchConfig();

  }

  async fetchConfig() {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();
      
      configuration.set({ configData: configValue.data }, { freeze: false });
      
      this.setState({ configLoading: false });

    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      this.setState({ configLoading: false });
    }
  }

  loadingFn() {

    this.props.setTimeout(() => { this.setState({ loading : false}) }, 3 * 1000);

  }

  componentDidMount() {

     //this.loadingFn();

  }

  render() {
    
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        // Place content loadder here
        <div>{/* <HomeLoader></HomeLoader> */}</div>
      );
    }
    
    /*const isLoading = this.state.loading;
    
    if (isLoading) {

      return (
        <div className="wrapper">
          <div className="loader-warpper">
              <div id="loader">
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
              </div>
          </div>
        </div>
      );

    } */
    
    return (
      <>
      <Helmet>
          <title>{configuration.get("configData.site_name")}</title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            // sizes="16x16"
          />
        </Helmet>
      <Router history={history}>
        <Switch>
            { /***Three column layout ****/}
            <AppRoute exact path={"/"} component={Home} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path={"/home"} component={Home} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path={"/trending"} component={Trending} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path={"/categories"} component={Category} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path={"/artists/:key?"} component={Artist} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path={"/albums/:key?"} component={Album} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path={"/songs"} component={Songs} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>

            <PrivateRoute authentication={this.state.authentication} path={"/recent-activities"} component={RecentActivity} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <PrivateRoute authentication={this.state.authentication} path={"/favorites"} component={Wishlist} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <PrivateRoute authentication={this.state.authentication} path={"/downloads"} component={Download} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>

            <PrivateRoute authentication={this.state.authentication} path={"/profile"} component={Profile} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <PrivateRoute authentication={this.state.authentication} path={"/edit-profile"} component={EditProfile} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <PrivateRoute authentication={this.state.authentication} path={"/change-password"} component={ChangePassword} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <PrivateRoute authentication={this.state.authentication} path={"/delete-account"} component={DeleteAccount} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>

            <AppRoute path={"/search-result/:key"} component={Search} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path={"/category-view/:id"} component={CategoryView} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path={"/artist-view/:id"} component={ArtistView} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path={"/album-view/:id"} component={AlbumView} layout={ThreeColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path={"/music/:id"} component={SingleAudio} layout={ThreeColumnLayout} screenProps={this.eventEmitter} />

            { /***Two column layout ****/}
            <PrivateRoute authentication={this.state.authentication} path={"/subscriptions"} component={Subscription} layout={TwoColumnLayout} screenProps={this.eventEmitter}/>
            <PrivateRoute authentication={this.state.authentication} path={"/myplans"} component={SubscribedPlan} layout={TwoColumnLayout} screenProps={this.eventEmitter}/>
            <PrivateRoute authentication={this.state.authentication} path={"/card/:is_subscription?"} component={Card} layout={TwoColumnLayout} screenProps={this.eventEmitter}/>
            <PrivateRoute authentication={this.state.authentication} path={"/payment-success"} component={PaymentSuccess} layout={TwoColumnLayout} screenProps={this.eventEmitter}/>
            <PrivateRoute authentication={this.state.authentication} path={"/payment-failure"} component={PaymentFailure} layout={TwoColumnLayout} screenProps={this.eventEmitter}/>

            <AppRoute path="/page/:id" component={Page} layout={TwoColumnLayout} screenProps={this.eventEmitter} />

            { /***Single column layout ****/}
            <PrivateRoute authentication={this.state.authentication} path={"/welcome"} component={Welcome} layout={SingleColumnLayout} screenProps={this.eventEmitter}/>
            <PrivateRoute authentication={this.state.authentication} path={"/language/:is_user?"} component={Language} layout={SingleColumnLayout} screenProps={this.eventEmitter}/>
            <AppRoute path="*" component={Error} layout={SingleColumnLayout} screenProps={this.eventEmitter}/>
            
        </Switch>
      </Router>
      </>
    );
  }
}

export default ReactTimeout(App);
