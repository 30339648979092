import React, { Component } from "react";
import { Link } from "react-router-dom";
import Background from "../../assets/img/see-all.jpg";
import { apiConstants } from "../Constant/Constants";
import api from "../../Environment";
var sectionStyle = {
  backgroundImage: `url(${Background})`
};
const $ = window.$;

class Download extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error_messages: "",

      isLoaded: false,

      items: [],

      no_of_songs: 0,

      isAuthenticated: this.props.data
    };

    this.wishlistOperation = this.wishlistOperation.bind(this);
  }

  componentDidMount() {
    api
      .postMethod("download/songs", { type: "downloads", skip: 0 })
      .then(
        response => {
          if (response.data.success) {
            this.setState({
              isLoaded: true,

              items: response.data.data,

              no_of_songs: response.data.no_of_songs
            });
          } else {
            this.setState({
              isLoaded: true,

              error_messages: response.data.error
            });
          }
        },

        error => {
          console.log(error);

          this.setState({
            isLoaded: true,

            error_messages: error
          });
        }
      )
      .catch(err => {
        this.setState({
          isLoaded: true,

          error_messages: "oops something went wrong"
        });
      });
  }

  wishlistOperation(e, song_id) {
    e.preventDefault();

    var currentTarget = e.target;

    api
      .postMethod("wishlist/operations", { song_id: song_id })
      .then(
        response => {
          if (response.data.success) {
            console.log(response.data);

            currentTarget.textContent =
              response.data.wishlist_id > 0
                ? "remove from Favorite"
                : "add to Favorite";
          } else {
            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  render() {
    const {
      error_messages,
      isLoaded,
      items,
      no_of_songs,
      isAuthenticated
    } = this.state;

    if (error_messages) {
      return <div className="body">Error : {error_messages}</div>;
    } else if (!isLoaded) {
      return (
        <div className="body">
          <div className="pl-3 pr-3">
            <div className="section-loader">
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="body-content">
          <div className="see-all-banner" style={sectionStyle}>
            <div className="see-all-overlay">
              <div>
                <h2>downloads</h2>
                <h4>{no_of_songs} songs</h4>
                <div className="button-sec">
                  {/* <button className="btn btn-warning btn-solid">play all</button> */}
                  <button className="btn btn-warning btn-outline">
                    delete all
                  </button>
                </div>
              </div>
            </div>
          </div>
          {items.map((item, index) => (
            <div key={index}>
              <div className="display-inline song-list">
                <Link to={"/"}>
                  <div className="queue-sec1">
                    <span>{index + 1}</span>
                    <div className="relative inline">
                      <img
                        src={item.picture}
                        onError={e => {
                          e.target.onError = "";
                          e.target.src = "../../assets/img/song1.jpg";
                        }}
                        className="song-img"
                        alt={item.title}
                      />
                      <img
                        src="../../assets/icons/playicon1.png"
                        className="song-play-icon"
                        alt="play_icon"
                      />
                    </div>
                  </div>
                  <div className="queue-sec2 downloads">
                    <h4>{item.title}</h4>
                    <h5
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                  <div className="queue-sec4 downloads">
                    {apiConstants.DOWNLOAD_COMPLETE_STAUTS ===
                    item.download_status ? (
                      <img
                        src="../../assets/icons/playicon1.png"
                        className="song-play-icon"
                        alt="play_icon"
                      />
                    ) : (
                      <img
                        src="../assets/icons/downloading-gif.gif"
                        alt="download-arrow"
                      />
                    )}
                  </div>
                </Link>
                <div className="queue-sec3 list">
                  <Link
                    to={"/"}
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <img
                      src="../../assets/icons/more-horizondal-grey.png"
                      alt="download-arrow"
                    />
                  </Link>
                  <div className="dropdown-menu">
                    <Link className="dropdown-item" to={"/"}>
                      delete
                    </Link>
                    <Link className="dropdown-item" to={"/"}>
                      share
                    </Link>
                    <Link className="dropdown-item" to={"/"}>
                      remove form queue
                    </Link>
                    {isAuthenticated && (
                      <div
                        className="dropdown-item"
                        onClick={e => this.wishlistOperation(e, item.song_id)}
                      >
                        {item.wishlist_status > 0
                          ? "remove from Favorite"
                          : "add to Favorite"}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <p className="queue-line" />
            </div>
          ))}
        </div>
      );
    }
  }
}

export default Download;
