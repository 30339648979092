import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import api from '../../Environment';

class Category extends Component {

    constructor(props) {

        super(props);

        this.state = {

            error_messages : '',

            isLoaded : false,

            items : [],
            
            sectionLoader : false,

            skipCount : 0

        }

        this.showMore = this.showMore.bind(this);

    }

    showMore(e) {

        this.categoryList(this.state.skipCount);

    }

    categoryList(skip) {

        if (this.state.skipCount > 0) {

            this.setState({

                sectionLoader : true

            })

        }

        api.postMethod("categories/list", {skip:skip})
        .then(
            (response) => {

                if (response.data.success) {

                    if (this.state.skipCount > 0) {

                        var items = [...this.state.items, ...response.data.data];

                        this.setState({

                            isLoaded : true,

                            items : items,

                            skipCount : this.state.skipCount+response.data.data.length,

                            sectionLoader : false

                        });

                    } else {

                        this.setState({

                            isLoaded : true,

                            items : response.data.data,

                            no_of_songs : response.data.no_of_songs,

                            skipCount : response.data.data.length

                        });
                    }

                } else {

                    this.setState({

                        isLoaded : true,

                        error_messages : response.data.error

                    });

                }

            },

            (error) => {

                console.log(error);

                this.setState({

                    isLoaded : true,

                    error_messages : error
                    
                });


            },
        )
        .catch(
            (err) => {

                this.setState({

                    isLoaded : true,

                    error_messages : "oops something went wrong"
                    
                });

            }
        );

    }

    componentDidMount() {

       this.categoryList(0);

    }

    render() {

        const {error_messages, isLoaded, items, sectionLoader} = this.state;

        if (error_messages) {
            
            return (<div className="body-content">Error : {error_messages}</div>);

        } else if (!isLoaded) {

            return (<div className="body-content">
                <div className="pl-3 pr-3">
                    <div className="section-loader">
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                    </div>
                </div>
            </div>);

        } else {

            return (
                <div className="body-content">
                    <div className="music-section">
                        <h2>categories</h2>
                        {items.length <= 0 &&
                            <div className="no-resultsec">
                                <img src="../../assets/img/no-result.png" className="no-result-img" alt="no-result"  />
                                <h4>Songs is empty</h4>
                                <p>No results found</p>
                            </div>
                        }
                        <div className="row">
                            {items.length > 0 && items.map((item, index) => (
                                <div className="col-6 col-sm-4 col-md-4 col-lg-6 col-xl-4" key={item.category_id}>
                                    <Link to={`/category-view/${item.category_id}`}>
                                        <img src={item.picture} onError={(e)=> { e.target.onError = ''; e.target.src="../../assets/img/cate-hip-hop-music.jpg"}} className="category-grid-img" alt={item.name} />
                                    
                                    <span className="text-center"><h6 className="cat-text">{item.name}</h6></span>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        {
                            sectionLoader && 
                            <div className="pl-3 pr-3">
                                <div className="section-loader">
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                </div>
                            </div>

                        }

                        {
                            items.length > 0 &&
                            <div className="text-right">
                                <button className="btn btn-solid btn-warning mr-0" onClick={e => this.showMore(e)}>show more</button>
                            </div>
                        }
                    </div>
                </div>

            );

        }

    }


}

export default Category;