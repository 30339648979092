import React, {Component} from 'react';

import { Link } from 'react-router-dom';

import api from '../../Environment';

class SubscribedPlan extends Component {

	constructor(props) {

        super(props);

        this.state = {

            error_messages : '',

            isLoaded : false,

            items : [],

        };

    }

    componentDidMount() {

        api.postMethod("subscribed/plans", {skip:0})
            .then(
                (response) => {

                    if (response.data.success) {

                        this.setState({

                            isLoaded : true,

                            items : response.data.data,

                        });

                    } else {

                        this.setState({

                            isLoaded : true,

                            error_messages : response.data.error

                        });

                    }

                },

                (error) => {

                    console.log(error);

                    this.setState({

                        isLoaded : true,

                        error_messages : error
                        
                    });


                },
            )
            .catch(
                (err) => {

                    this.setState({

                        isLoaded : true,

                        error_messages : "oops something went wrong"
                        
                    });

                }
            );

	}
	
    render() {

		const {error_messages, isLoaded, items} = this.state;

        if (error_messages) {

            return (<div className="body">Error : {error_messages}</div>)

        } else if (!isLoaded) {

            return (<div className="body">
                <div className="pl-3 pr-3">
                    <div className="section-loader">
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                    </div>
                </div>
            </div>)

        } else  {

			return (

				<div className="body">
					<div className="section-spacing">
						<div className="music-section">
							<h2>my subscription <Link to={"/subscriptions"} className="see-all"><span>subscription plans</span></Link></h2>
						</div>

						<div className="row">
							<div className="col-12 col-sm-10 offset-sm-1 offset-md-0 col-md-12 offset-lg-1 col-lg-10 offset-xl-0 col-xl-12">
                                {items.length <= 0 &&
                                    <div className="no-resultsec">
                                        <img src="../../assets/img/no-result.png" className="no-result-img" alt="no-result"  />
                                        <h4>My subscriptions is empty</h4>
                                        <p>Subscribe and get the songs to download</p>
                                    </div>
                                }
								<div className="row">
									{items.map((item,index)=>(
										<div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
											<div className="subscription">
												<div className="subscription-title">{item.title}</div>
												<div className="subscription-enddate">
													<img src="../assets/icons/download-arrow-white.png" alt="download arrow"/>
													<span className="middle">{item.no_of_songs} songs download</span>
												</div>
												<ul className="subscription-text">
													<li>payment mode : {item.payment_mode}</li>
													<li>Transaction Id : {item.payment_id}</li>
													<li>download upto {item.no_of_songs} songs</li>
													<li dangerouslySetInnerHTML={{__html:item.description}}></li>
                                                    <li>Paid Date : {item.paid_date_formatted}</li>

												</ul>
												<p className="subscription-line"></p>
												<div className="subscription-amount">
													{item.currency}{item.paid_amount}<span className="small">&nbsp;/&nbsp;{item.no_of_songs} Songs</span>
												</div>
												<div className="active-plan">
													<img src="../../assets/icons/medal.png" className="" alt="active-medal" />
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
								
					</div>
				</div>

			);

		}

    }

}

export default SubscribedPlan;