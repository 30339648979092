import React, {Component} from 'react';

import Slider from "react-slick";

import {Link} from 'react-router-dom';

import api from '../../Environment';

const $ = window.$;

class Search extends Component {

    constructor(props) {

        super(props);

        const {match : {params}} = this.props;

        this.state = {

            error_messages : '',

            isLoaded : false,

            song_items : [],    
            
            artist_items : [],    

            album_items : [],    

            isAuthenticated:this.props.data,

            search_key : params.key
        }

        this.eventEmitter = this.props.eventEmitter;

        this.addQueue = this.addQueue.bind(this);
    }


    addQueue(e, object) {

        e.preventDefault();

        var queue_songs = [];

        queue_songs = localStorage.getItem("queue_songs") ? JSON.parse(localStorage.getItem("queue_songs")) : [];

        let already_value_exists = 0;

        if (queue_songs.length > 0) {

            if (queue_songs.some(item => item.song_id === object.song_id)) {

                already_value_exists = 1;

            }
        }

        if (!already_value_exists) {

            this.eventEmitter.emit("queue_listen", object);

            $.toast({
                heading: 'Success',
                text: "Added to queue",
                icon: 'success',
                position: 'top-right',
                stack: false,
                textAlign: 'left',
                loader : false,
                showHideTransition: 'slide'
            });

        } else {

            $.toast({
                heading: 'Error',
                text: "Already Added to the queue",
                icon: 'error',
                position: 'top-right',
                stack: false,
                textAlign: 'left',
                loader : false,
                showHideTransition: 'slide'
            });

        }

    }

    searchResultSection() {

        api.postMethod("search", {term : this.state.search_key})
        .then(

            (response)=> {

                if (response.data.success) {

                    var datas = response.data.data;

                    this.setState({

                        isLoaded : true,

                        song_items : datas.songs,

                        artist_items : datas.artists,

                        album_items : datas.albums
                    });

                } else {

                  console.log(response.data.error);

                }

            },
            (error) => {

                console.log(error);


            },
        )
        .catch(
            (err) => {

               console.log("oops something went wrong");

            }
        );


    }

    componentDidMount() {

        this.searchResultSection();

    }

    render() {

        var songs = {
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        };

        var artist = {
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        };

        var albums = {
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };

        const {song_items, isLoaded, artist_items, album_items, search_key} = this.state;

        if (!isLoaded) {

            return (<div className="body-content" ref={ (divElement) => this.divElement = divElement}>
                <div className="pl-3 pr-3">
                    <div className="">
                        <div className="section-loader">
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                        </div>
                    </div>
                </div>
            </div>);

        } else {

            return (

                <div className="body-content">
                    <div className="pl-3 pr-3">
                        <div className="music-section">
                            <h2>search result of "{search_key}"</h2>
                        </div>

                        {(artist_items.data.length <= 0  && album_items.data.length <= 0 && song_items.data.length <= 0) &&  

                            <div className="no-resultsec">
                                <img src="../../assets/img/no-result.png" className="no-result-img" alt="no-result"  />
                                <h4>Songs is empty</h4>
                                <p>"{search_key}" not found in search results</p>
                            </div>

                        }
                        { song_items.data.length > 0 && 
                            <div className="music-section">
                                <h2>{song_items.name} <Link to={'/trending'} className="see-all"><span>see all</span></Link></h2>
                                <Slider className="trending slider" {...songs}>
                                    { song_items.data.map((song_item, index)=>(
                                        <div key={index} onClick={e => this.addQueue(e, song_item)} className="cursor">
                                            <div className="relative">
                                                <img src={song_item.picture} onError={(e)=>{e.target.onError=''; e.target.src="../../assets/icons/playicon.png"}} alt={song_item.title} />
                                                <div className="trending-overlay">
                                                    <img src="../../assets/icons/playicon.png" className="playicon" alt="playicon" />
                                                </div>
                                            </div>
                                            <h4 className="song-title">{song_item.title}</h4>
                                        </div>
                                    ))}
                                    
                                </Slider>
                            </div>
                        }
                    
                        { artist_items.data.length > 0 && 
                            <div className="music-section">
                                <h2>{artist_items.name} <Link to={`/artists/${artist_items.term}`} className="see-all"><span>see all</span></Link></h2>
                                <Slider className="artist slider" {...artist}>
                            
                                     {artist_items.data.map((artist_item,index)=>(
                                        <div key={index}>
                                            <Link to={`/artist-view/${artist_item.artist_id}`}>
                                                <img src={artist_item.picture} onError={(e)=> {e.target.onError ='';e.target.src="../../assets/img/artist1.jpg"}} alt={artist_item.name}/>
                                                <h4 className="artist-name">{artist_item.name}</h4>   	
                                            </Link>
                                        </div>
                                    ))}
                                    
                                </Slider>
                            </div>
                        }
                        {album_items.data.length > 0 &&
                        <div className="music-section">
                            <h2>{album_items.name} <Link to={`/albums/${album_items.term}`} className="see-all"><span>see all</span></Link></h2>
                            <Slider className="albums slider" {...albums}>
                                {album_items.data.map((album_item, index)=>(
                                        <div key={index}>
                                            <Link to={`/album-view/${album_item.movie_album_id}`}>
                                                <div className="albums-sec">
                                                    <div className="relative">
                                                        <img src={album_item.picture} onError={(e)=> {e.target.onError ='';e.target.src="../../assets/img/song1.jpg"}} alt={album_item.name} />
                                                        <div className="trending-overlay">
                                                            <img src="assets/icons/playicon.png" className="playicon" alt="playicon" />
                                                        </div>
                                                    </div>
                                                    <div className="albums-details">
                                                        <h4 className="title">{album_item.name}</h4>
                                                        <h5 className="name">{album_item.description}</h5>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                            </Slider>
                        </div>
                        }
                    </div>
                </div>
                
            );
        }
    }
}

export default Search;