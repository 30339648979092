import React, {Component} from 'react';

import {CardNumberElement, CardExpiryElement,CardCVCElement , injectStripe} from 'react-stripe-elements';

import api from '../../Environment';

import {Redirect} from 'react-router-dom';

const $ = window.$;

class CheckoutForm extends Component {
    
  constructor(props) {

    super(props);

    this.submit = this.submit.bind(this);

    this.state = {

        subscription_redirection : localStorage.getItem('is_subscription'),

        redirect : false

    }

    console.log(this.state.subscription_redirection);

  }

  async submit(ev) {

    ev.preventDefault();

    // User clicked submit

    this.props.stripe.createToken()
    .then((payload) => {

        console.log(payload.token.id);

        this.addCard(payload.token.id);

    });


  }

  addCard(card_token) {

    api.postMethod("cards_add", {card_token:card_token})
    .then(
        (response) => {

            if (response.data.success) {


                $.toast({
                    heading: 'Success',
                    text: response.data.message,
                    icon: 'success',
                    position: 'top-right',
                    stack: false,
                    textAlign: 'left',
                    loader : false,
                    showHideTransition: 'slide'
                });

                setTimeout(()=>{

                    window.location.reload(); 

                }, 1000);

            } else {

                console.log(response.data.error);

                $.toast({
                    heading: 'Error',
                    text: response.data.error,
                    icon: 'error',
                    position: 'top-right',
                    stack: false,
                    textAlign: 'left',
                    loader : false,
                    showHideTransition: 'slide'
                });

            }

        },

        (error) => {

            console.log(error);


        },
    )
    .catch(
        (err) => {

            console.log("oops something went wrong");

        }
    );

  }



  render() {

    if (this.state.redirect) {

        localStorage.removeItem('is_subscription');

        if (this.state.subscription_redirection === 1) {

            return <Redirect to={'/subscriptions'} />;

        }

        return <Redirect to={'/card'} />;

    }

    return (
        <form className="auth-form">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <img src="../../assets/icons/account-gold.png" className="auth-img" alt="icon" />
                    </span>
                </div>
                <CardNumberElement className="form-control"/>
            </div>

            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <img src="../../assets/icons/lock-gold.png" className="auth-img" alt="icon" />
                    </span>
                </div>
                <CardCVCElement className="form-control"/>
            </div>

            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <img src="../../assets/icons/calendar-gold.png" className="auth-img" alt="icon" />
                    </span>
                </div>
                <CardExpiryElement className="form-control"/>
            </div>
                

            <div className="">
                <button className="btn btn-warning btn-solid mt-0 mr-0"  onClick={this.submit}>add card</button>
            </div>
        </form> 

    );
  }
}

export default injectStripe(CheckoutForm);
