import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import Background from '../../assets/img/sidebar.png';

import api from '../../Environment';

var sectionStyle = {
    backgroundImage: `url(${Background})`
};

const $ = window.$;

class Subscription extends Component {

    constructor(props) {

        super(props);

        this.state = {

            error_messages : '',

            isLoaded : false,

            items : [],

            success_redirection : false,

            failure_redirection : false,

            card_redirection : false,
            
            from_subscription_page : 0

        };

        this.paymentOperation = this.paymentOperation.bind(this);

    }

    componentDidMount() {

        api.postMethod("subscription/plans", {skip:0})
            .then(
                (response) => {

                    if (response.data.success) {

                        this.setState({

                            isLoaded : true,

                            items : response.data.data,

                        });

                    } else {

                        this.setState({

                            isLoaded : true,

                            error_messages : response.data.error

                        });

                    }

                },

                (error) => {

                    console.log(error);

                    this.setState({

                        isLoaded : true,

                        error_messages : error
                        
                    });


                },
            )
            .catch(
                (err) => {

                    this.setState({

                        isLoaded : true,

                        error_messages : "oops something went wrong"
                        
                    });

                }
            );

    }

    paymentOperation(e, subscription_id) {

        e.preventDefault();

        api.postMethod("subscription-payment-stripe", {subscription_id : subscription_id})
        .then(
            (response) => {

                $("#invoice_"+subscription_id).click();

                if (response.data.success) {

                    this.setState({

                        success_redirection : true,

                    });

                    $.toast({
                        heading: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                } else {

                    $.toast({
                        heading: 'Error',
                        text: response.data.error,
                        icon: 'error',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                   if (response.data.error_code === 112) {

                        this.setState({

                            card_redirection : true,

                            from_subscription_page : subscription_id

                        })


                   } else {

                        this.setState({

                            failure_redirection : true
                        })

                   }

                }

            },

            (error) => {

                console.log(error);


            },
        )
        .catch(
            (err) => {

                console.log("oops something went wrong");

            }
        );

    }

    render() {

        const {error_messages, isLoaded, items, success_redirection, failure_redirection, card_redirection, from_subscription_page} = this.state;

        if (success_redirection) {

            return <Redirect to={'/payment-success'} />;

        }

        if (card_redirection) {

            return <Redirect to={`/card/${from_subscription_page}`} />;

        }

        if (failure_redirection) {

            return <Redirect to={'/payment-failure'} />;

        }

        if (error_messages) {

            return (<div className="body">Error : {error_messages}</div>)

        } else if (!isLoaded) {

            return (<div className="body">
                <div className="section-spacing">
                    <div className="section-loader">
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                    </div>
                </div>
            </div>)

        } else {

            return (

                <div className="body">
                    <div className="section-spacing">
                        <div className="music-section">
                            <h2>subscription <Link to={'/myplans'} className="see-all"><span>my subscription</span></Link></h2>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-10 offset-sm-1 offset-md-0 col-md-12 offset-lg-1 col-lg-10 offset-xl-0 col-xl-12">
                                {items.length <= 0 &&
                                    <div className="no-resultsec">
                                        <img src="../../assets/img/no-result.png" className="no-result-img" alt="no-result"  />
                                        <h4>Subscription is empty</h4>
                                        <p>No results found</p>
                                    </div>
                                }
                                <div className="row">
                                    {items.length > 0 && items.map((item,index)=>(
                                        <div key={item.subscription_id} className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div>
                                                <div className="subscription">
                                                    <div className="subscription-title">{item.title}</div>
                                                    <div className="subscription-amount">
                                                        {item.currency}{item.amount}<span className="small">&nbsp;/&nbsp;{item.no_of_songs} Songs</span>
                                                    </div>
                                                    <ul className="subscription-text">
                                                        <li>download upto {item.no_of_songs} songs</li>
                                                        <li dangerouslySetInnerHTML={{__html: item.description}}></li>
                                                    </ul>
                                                    <p className="subscription-line"></p>
                                                    <div className="text-center">
                                                        <button className="btn btn-warning btn-solid mb-3" data-toggle="modal" data-target={`#invoice_${item.subscription_id}`}>choose plan</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal fade" id={`invoice_${item.subscription_id}`}>
                                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="row m-0">
                                                            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 p-0">
                                                                <div className="invoice-img" style={ item.picture ? {backgroundImage: `url(${item.picture})`} : sectionStyle}>
                                                                    invoice
                                                                </div>
                                                            </div>	
                                                            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 p-0 invoice-right-sec">
                                                                <div className="modal-header">
                                                                    <h3 className="modal-title">invoice</h3>
                                                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <h3 className="invoice-head">{item.name}</h3>
                                                                    <ul className="invoice-text">    
                                                                        <li>download upto {item.no_of_songs} songs</li>
                                                                        <li dangerouslySetInnerHTML={{__html:item.description}}></li>
                                                                    </ul>
                                                                    <h3 className="invoice-head">payment details</h3>
                                                                    <table className="table table-striped mt-3 mb-5">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Amount</td>
                                                                                <td>{item.currency}{item.amount}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Tax</td>
                                                                                <td>{item.currency}0</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Total</td>
                                                                                <td>{item.currency}{item.amount}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    { /*
                                                                    <h3 className="invoice-head">payment method</h3>
                                                                    <form>
                                                                        <input type="radio" id="radio1" name="radio-category" defaultChecked/>
                                                                        <label htmlFor="radio1">paypal</label>

                                                                        <input type="radio" id="radio2" name="radio-category" />
                                                                        <label htmlFor="radio2">card</label>							
                                                                    </form> */ }
                                                                    <div className="text-right">
                                                                        <button className="btn btn-warning btn-solid" onClick={e => this.paymentOperation(e, item.subscription_id)}>pay now</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>  

            );

        }

    }

}

export default Subscription;