import React,{Component} from 'react';

import { Link } from 'react-router-dom';

class PaymentFailure extends Component {
    render() {
        return (
            <div className="body">
                <div className="section-spacing">
                    <div className="payment-status-sec">
                       <img src="assets/img/failure.gif" className="payment-status-img" alt="payment-failure" />
                       <div className="payment-status">
                           <h3>payment failure ..!!</h3>
                           <h3>your payment was not received! Please try again.</h3>
                            <Link to={'/subscriptions'} className="btn btn-warning mr-0  mt-2 btn-solid">
                                go back
                            </Link>
                       </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentFailure;