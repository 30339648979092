import React, {Component} from 'react';

import PageSideber from "./PageSideber";

import api from '../../Environment';

class Page extends Component {

    constructor(props) {

        super(props);

        this.state = {
            
            page_items : [],

            error_messages : "",

            isLoaded : false,
            
        };



    }

    pagesList() {

        api.getMethod("pages/list",{})
            .then(
                (response) => {

                    if (response.data.success) {

                        
                        this.setState({

                            isLoaded : true,

                            page_items : response.data.data,

                        });

                    } else {

                        this.setState({

                            isLoaded : true,

                            error_messages : response.data.error

                        });

                    }

                },

                (error) => {

                    this.setState({

                        isLoaded : true,

                        error_messages : error
                        
                    });


                },
            )
            .catch(
                (err) => {

                    this.setState({

                        isLoaded : true,

                        error_messages : "oops something went wrong"
                        
                    });

                }
            );
            
    }
    componentDidMount() {

        this.pagesList();
    }

    render(){

        const {match : {params}} = this.props;

        const {page_items, isLoaded, error_messages} = this.state;

        const pageID = params.id;

        if (error_messages) {

            return (<div className="body">Error : {error_messages}</div>)

        } else if (!isLoaded) {

            return (<div className="body">
                <div className="section-spacing">
                    <div className="section-loader">
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                    </div>
                </div>
            </div>)

        } else  {

            

            return(
                <div className="body">
                    <div className="section-spacing">
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-xl-3">
                                <PageSideber pageItems={page_items}></PageSideber>
                            </div>
                            <div className="col-md-8 col-lg-8 col-xl-9">

                                { page_items.filter(item => Number(item.page_id) === Number(pageID)).map(item => {

                                    if (Number(item.page_id) === Number(pageID)) {
                                        
                                        return (
                                            <div key={item.page_id}>
                                                <div className="music-section">
                                                    <h2>{item.title}</h2>
                                                </div>
                                                <div className="static-text">
                                                    <div dangerouslySetInnerHTML={{__html :item.description}}></div>
                                                </div>
                                            </div>
                                        )
                                    }

                                })}

                            </div>
                        </div>
                        
                    </div>
                </div>
            )

        }
    }

}

export default Page;