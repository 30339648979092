import React,{Component} from 'react';

import { Link } from 'react-router-dom';

class PaymentSuccess extends Component {
    render() {
        return (
            <div className="body">
                <div className="section-spacing">
                    <div className="payment-status-sec">
                       <img  src="assets/img/success.gif" className="payment-status-img" alt="payment-success" />
                       <div className="payment-status">
                           <h3>Thank You ..!!</h3>
                           <h3>your payment has been received successfully.!!</h3>
                            <Link to="/myplans" className="btn btn-warning mr-0  mt-2 btn-solid">
                                My Plans
                            </Link>
                       </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentSuccess;