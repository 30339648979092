import React, {Component} from 'react';

import Header from './Header';

import LeftSidebar from './LeftSidebar';

import RightSidebar from '../Layout/RightSidebar';

import SongView from '../Home/SongView';

import Login from '../Home/Login';

import Register from '../Home/Register';

import ForgetPassword from '../Home/ForgetPassword';

import {
    FacebookShareButton,
    TwitterShareButton
  } from 'react-share';


const $ = window.$;

class ThreeColumnLayout extends Component {
    
    constructor(props) {

        super(props);

        this.eventEmitter = this.props.screenProps;

        let userId = (localStorage.getItem('userId') !== '' && localStorage.getItem('userId') !== null && localStorage.getItem('userId') !== undefined) ? localStorage.getItem('userId') : '';

        let accessToken = (localStorage.getItem('accessToken') !== '' && localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== undefined) ? localStorage.getItem('accessToken') : '';

        this.state = {
            
            isAuthenticated : userId && accessToken ? true : false,

            song_details : []

        }

    }

    componentDidMount() {

        this.eventEmitter.on("open_toggleview", (e) => {

           $(".wrapper").toggleClass("active");

        });

        this.eventEmitter.on("share_details", (e) => {

           // $(".share-btn").attr("id", e.id);

           console.log(e);

            this.setState({
                song_details : e
            });
 
         });

    }

    componentWillUnmount() {

        this.eventEmitter.removeEventListener("open_toggleview", (e) => {

        });
        this.eventEmitter.removeEventListener("share_details", (e) => {
            
            this.setState({

                song_details : []
            })
        });

    }

    render() {

        const {song_details,isAuthenticated} = this.state;

        return (
                <div className="wrapper">
                    <LeftSidebar data={isAuthenticated}/>
                    <div className="main-sec">
                        <Header data={isAuthenticated} eventEmitter={this.eventEmitter}/>
                        <div className="header-height"></div>
                        <div className="display-inline">
                            {React.cloneElement(this.props.children, {eventEmitter : this.eventEmitter, data : isAuthenticated})}
                            <RightSidebar data={isAuthenticated} eventEmitter={this.eventEmitter}/>
                        </div>
                        <div className="body-height"></div>
                    </div> 

                    <SongView eventEmitter={this.eventEmitter} isAuthenticated={isAuthenticated}/>

                    <Login />

                    <ForgetPassword />

                    <Register />

                    {song_details &&
                    <div className="modal fade sharebtn" id="sharebtn">
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                            
                                <div className="modal-header">
                                    <h3 className="modal-title">share</h3>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                            
                                <div className="modal-body">
                                    <div className="row social-share">
                                        <div className="col-6">
                                            
                                            <FacebookShareButton url={song_details.song_share_url}  quote={song_details.title}>
                                                <img src="../../assets/icons/facebook.png" alt="facebook"/>
                                                <p>facebook</p>
                                            </FacebookShareButton>
                                            
                                            { /*<GooglePlusShareButton  url="https://streamtunes.botfingers.com/#/music/55">google</GooglePlusShareButton>*/}
                                        </div>
                                        <div className="col-6">
                                            
                                            <TwitterShareButton  url={song_details.song_share_url} title={song_details.title} description={song_details.description}>
                                                <img src="../../assets/icons/twitter.png" alt="twitter"/>
                                                <p>twitter</p>
                                            </TwitterShareButton>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>}
                </div>
            );
    }
}
export default ThreeColumnLayout;

