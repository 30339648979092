import React, { Component } from "react";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import api from "../../Environment";

import { apiConstants } from "../Constant/Constants";
const $ = window.$;

class JinkeMusicPlayer extends Component {
  constructor(props) {
    super(props);

    this.eventEmitter = this.props.eventEmitter;

    this.onPlaying = this.onPlaying.bind(this);

    this.state = {
      current_song_id: "",

      isAuthenticated: this.props.isAuthenticated,
      params: null,
      loading: true
    };
    this.listOfSongs = [];
  }

  onPlaying(e) {
    console.log("Play tiggered");
    if (this.state.isAuthenticated) {
      var current_song_id = this.listOfSongs
        .filter(event => event.src === e.target.musicSrc)
        .map(output => {
          return output.id;
        });

      this.setState({
        current_song_id: current_song_id
      });

      this.callHistory(current_song_id, apiConstants.HISTORY_INITIATE_STATUS);
    }
  }

  componentDidMount() {
    let options = {
      //audio lists model
      //   audioLists: [],

      //default play index of the audio player  [type `number` default `0`]
      defaultPlayIndex: 0,

      //if you want dynamic change current play audio you can change it [type `number` default `0`]
      // playIndex: 0,

      //color of the music player theme    [ type `string: 'light' or 'dark'  ` default 'dark' ]
      theme: "dark",

      // Specifies movement boundaries. Accepted values:
      // - `parent` restricts movement within the node's offsetParent
      //    (nearest node with position relative or absolute), or
      // - a selector, restricts movement within the targeted node
      // - An object with `left, top, right, and bottom` properties.
      //   These indicate how far in each direction the draggable
      //   can be moved.
      bounds: "body",

      //Whether to load audio immediately after the page loads.  [type `Boolean | String`, default `false`]
      //"auto|metadata|none" "true| false"
      preload: false,

      //Whether the player's background displays frosted glass effect  [type `Boolean`, default `false`]
      glassBg: false,

      //The next time you access the player, do you keep the last state  [type `Boolean` default `false`]
      remember: false,

      //The Audio Can be deleted  [type `Boolean`, default `true`]
      remove: true,

      //audio controller initial position    [ type `Object` default '{top:0,left:0}' ]
      defaultPosition: {
        top: 158,
        left: 150
      },

      // play mode text config of the audio player
      playModeText: {
        order: "顺序播放",
        orderLoop: "列表循环",
        singleLoop: "单曲循环",
        shufflePlay: "随机播放"
      },

      //audio controller open text  [ type `String | ReactNode` default 'open']
      openText: "Expand",

      //audio controller close text  [ type `String | ReactNode` default 'close']
      closeText: "Close",

      //audio theme switch checkedText  [ type `String | ReactNode` default '-']
      checkedText: "D",

      //audio theme switch unCheckedText [ type `String | ReactNode` default '-']
      unCheckedText: "W",

      // audio list panel show text of the playlist has no songs [ type `String` | ReactNode  default 'no music']
      notContentText: "No Songs",

      panelTitle: "PlayList",

      defaultPlayMode: "order",

      //audio mode        mini | full          [type `String`  default `mini`]
      mode: "full",

      /**
       * [ type `Boolean` default 'false' ]
       * The default audioPlay handle function will be played again after each pause, If you only want to trigger it once, you can set 'true'
       */
      once: true,

      //Whether the audio is played after loading is completed. [type `Boolean` default 'true']
      autoPlay: true,

      //Whether you can switch between two modes, full => mini  or mini => full   [type 'Boolean' default 'true']
      toggleMode: false,

      //audio cover is show of the "mini" mode [type `Boolean` default 'true']
      showMiniModeCover: true,

      //audio playing progress is show of the "mini"  mode
      showMiniProcessBar: true,

      //audio controller is can be drag of the "mini" mode     [type `Boolean` default `true`]
      drag: false,

      //drag the audio progress bar [type `Boolean` default `true`]
      seeked: true,

      //Displays the audio load progress bar.  [type `Boolean` default `true`]
      showProgressLoadBar: true,

      //play button display of the audio player panel   [type `Boolean` default `true`]
      showPlay: true,

      //reload button display of the audio player panel   [type `Boolean` default `true`]
      showReload: true,

      //download button display of the audio player panel   [type `Boolean` default `true`]
      showDownload: false,

      //loop button display of the audio player panel   [type `Boolean` default `true`]
      showPlayMode: true,

      //theme toggle switch  display of the audio player panel   [type `Boolean` default `true`]
      showThemeSwitch: true,

      //Extensible custom content       [type 'Array' default '[]' ]
      extendsContent: [],

      //default volume of the audio player [type `Number` default `100` range `0-100`]
      defaultVolume: 100,

      //playModeText show time [type `Number(ms)` default `700`]
      playModeShowTime: 600,

      // Play and pause audio through blank space [type `Boolean` default `false`]
      spaceBar: true,

      //Whether to try playing the next audio when the current audio playback fails [type `Boolean` default `true`]
      loadAudioErrorPlayNext: true,

      //Music is downloaded handle
      onAudioDownload(audioInfo) {
        // swal("download successfully", "", "success");
        console.log("audio download", audioInfo);
      },

      //audio play handle
      onAudioPlay(audioInfo) {
        console.log("audio playing", audioInfo.musicSrc);
      },

      //audio pause handle
      onAudioPause(audioInfo) {
        console.log("audio pause", audioInfo);
      },

      //When the user has moved/jumped to a new location in audio
      onAudioSeeked(audioInfo) {
        console.log("audio seeked", audioInfo);
      },

      //When the volume has changed  min = 0.0  max = 1.0
      onAudioVolumeChange(currentVolume) {
        console.log("audio volume change", currentVolume);
      },

      //The single song is ended handle
      onAudioEnded(audioInfo) {
        // swal("Audio is ended!", "", "success");
        console.log("audio ended", audioInfo);
      },

      //audio load abort The target event like {...,audioName:xx,audioSrc:xx,playMode:xx}
      onAudioAbort(e) {
        console.log("audio abort", e);
      },

      //audio play progress handle
      onAudioProgress(audioInfo) {
        // console.log('audio progress',audioInfo);
      },

      //audio reload handle
      onAudioReload(audioInfo) {
        console.log("audio reload:", audioInfo);
      },

      //audio load failed error handle
      onAudioLoadError(e) {
        // swal("audio load error", "", "error");
        console.log("audio load err", e);
      },

      //theme change handle
      onThemeChange(theme) {
        console.log("theme change:", theme);
      },

      onAudioListsChange(currentPlayId, audioLists, audioInfo) {
        console.log("[currentPlayId] audio lists change:", currentPlayId);
        console.log("[audioLists] audio lists change:", audioLists);
        console.log("[audioInfo] audio lists change:", audioInfo);

        var queue_songs = localStorage.getItem("queue_songs")
          ? JSON.parse(localStorage.getItem("queue_songs"))
          : [];

        console.log("Queue songs", queue_songs);

        let count = 0;

        if (queue_songs.length > 0) {
          queue_songs.some((item, index) => {
            console.log("Item song" + item.song_id + "Index" + index);
            if (audioLists.length === 0) {
              $.toast({
                heading: "Success",
                text: item.title + " Song has been removed from queue",
                icon: "success",
                position: "top-right",
                stack: false,
                textAlign: "left",
                loader: false,
                showHideTransition: "slide"
              });
              console.log("Treue");
              queue_songs.splice(index, 1);
              localStorage.setItem("queue_songs", JSON.stringify(queue_songs));
              queue_songs = localStorage.getItem("queue_songs")
                ? JSON.parse(localStorage.getItem("queue_songs"))
                : [];
            } else {
              if (typeof audioLists[index] === "undefined") {
                $.toast({
                  heading: "Success",
                  text: item.title + " Song has been removed from queue",
                  icon: "success",
                  position: "top-right",
                  stack: false,
                  textAlign: "left",
                  loader: false,
                  showHideTransition: "slide"
                });
                console.log("Treue");
                queue_songs.splice(index, 1);
                localStorage.setItem(
                  "queue_songs",
                  JSON.stringify(queue_songs)
                );
                queue_songs = localStorage.getItem("queue_songs")
                  ? JSON.parse(localStorage.getItem("queue_songs"))
                  : [];
              } else {
                if (item.song_id !== audioLists[index].id) {
                  $.toast({
                    heading: "Success",
                    text: item.title + " Song has been removed from queue",
                    icon: "success",
                    position: "top-right",
                    stack: false,
                    textAlign: "left",
                    loader: false,
                    showHideTransition: "slide"
                  });
                  console.log("Treue");
                  queue_songs.splice(index, 1);
                  localStorage.setItem(
                    "queue_songs",
                    JSON.stringify(queue_songs)
                  );
                  queue_songs = localStorage.getItem("queue_songs")
                    ? JSON.parse(localStorage.getItem("queue_songs"))
                    : [];
                } else {
                  count++;
                  if (count === audioLists.length) {
                    console.log("Got you");
                  }
                }
              }
            }
          });
        }
      },

      onAudioPlayTrackChange(currentPlayId, audioLists, audioInfo) {
        console.log(
          "audio play track change:",
          currentPlayId,
          audioLists,
          audioInfo
        );
      },

      onPlayModeChange(playMode) {
        console.log("play mode change:", playMode);
      },

      onModeChange(mode) {
        console.log("mode change:", mode);
      },

      onAudioListsPanelChange(panelVisible) {
        console.log("audio lists panel visible:", panelVisible);
      },

      onAudioListsDragEnd(fromIndex, endIndex) {
        console.log("audio lists drag end:", fromIndex, endIndex);
      }
    };
    this.setState({ params: options, loading: false });
  }
  onAddAudio = () => {
    const data = {
      ...this.state.params,
      audioLists: [
        ...this.state.params.audioLists,
        {
          name: "I'm new here",
          singer: "jack",
          cover: "http://www.lijinke.cn/music/1387583682387727.jpg",
          musicSrc: `http://www.lijinke.cn/music/${Date.now()}.mp3`
        }
      ]
    };
    this.setState({
      params: data
    });
  };
  extendsContent = () => {
    const data = {
      ...this.state.params,
      extendsContent: [
        <button key="button" onClick={() => alert("I'm extends content")}>
          button
        </button>
      ]
    };
    this.setState({
      params: data
    });
  };

  onEnded(e) {
    console.log(this.state.current_song_id);

    if (this.state.isAuthenticated) {
      this.callHistory(
        this.state.current_song_id,
        apiConstants.HISTORY_COMPLETE_STATUS
      );
    }
  }

  callHistory(song_id, history_status) {
    console.log("History called", song_id);
    api
      .postMethod("history/operations", {
        song_id: song_id,
        history_status: history_status
      })
      .then(
        response => {
          if (response.data.success) {
            console.log("History status " + history_status);
          } else {
            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }
  onShowGlassBg = () => {
    this.onChangeKey("glassBg");
  };
  onDrag = () => {
    this.onChangeKey("drag");
  };
  onToggleMode = () => {
    this.onChangeKey("toggleMode");
  };
  onSeeked = () => {
    this.onChangeKey("seeked");
  };
  onChangeKey = key => {
    const data = {
      ...this.state.params,
      [key]: !this.state.params[key]
    };
    this.setState({ params: data });
  };
  showMiniProcessBar = () => {
    this.onChangeKey("showMiniProcessBar");
  };
  showMiniModeCover = () => {
    this.onChangeKey("showMiniModeCover");
  };
  //   playModeShowTime = () => {
  //     const data = {
  //       ...this.state.params,
  //       playModeShowTime: createRandomNum(200, 2000)
  //     };
  //     this.setState({
  //       params: data
  //     });
  //   };
  changePlayIndex = () => {
    const data = {
      ...this.state.params,
      playIndex: this.state.params.audioLists.length
    };
    this.setState({
      params: data
    });
  };
  render() {
    let { params, loading } = this.state;

    const { songs } = this.props;

    this.listOfSongs = [];

    for (var i = 0; i < songs.length; i++) {
      var song = songs[i];

      this.listOfSongs.push({
        id: song.song_id,
        name: song.title,
        singer: "",
        cover: song.picture,
        musicSrc: song.web_audio_url,
        src: song.web_audio_url
      });
    }

    if (!loading) {
      console.log("Loaded");
      params.onAudioPlay = audioInfo => {
        if (this.state.isAuthenticated) {
          var current_song_id = this.listOfSongs
            .filter(event => event.src === audioInfo.musicSrc)
            .map(output => {
              return output.id;
            });
          console.log("checking");

          this.setState({
            current_song_id: current_song_id
          });

          this.callHistory(
            current_song_id,
            apiConstants.HISTORY_INITIATE_STATUS
          );
        }
        console.log("I dont");
      };
    }

    let allDetails = {
      ...params,
      audioLists: this.listOfSongs
    };

    return <ReactJkMusicPlayer {...allDetails} />;
  }
}

export default JinkeMusicPlayer;
