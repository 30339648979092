import React, { Component } from "react";

import { Link } from "react-router-dom";

import api from "../../Environment";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_details: {},

      isLoaded: false
    };
  }

  userProfile() {
    api
      .postMethod("profile", {})
      .then(
        response => {
          if (response.data.success) {
            localStorage.setItem("accessToken", response.data.data.token);

            localStorage.setItem("userId", response.data.data.user_id);

            this.setState({
              user_details: response.data.data,

              isLoaded: true
            });
          } else {
            console.log(response.data.error);
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  componentDidMount() {
    this.userProfile();
  }

  render() {
    const { user_details, isLoaded } = this.state;

    if (!isLoaded) {
      return (
        <div className="body-content">
          <div className="pl-3 pr-3">
            <div className="section-loader">
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="body-content">
          <div className="music-section">
            <h2>profile</h2>
            <div className="display-inline">
              <div className="editprofile-image-sec">
                <img
                  src={user_details.picture}
                  onError={e => {
                    e.target.onError = "";
                    e.target.src = "../../assets/img/song3.jpg";
                  }}
                  className="edit-img"
                  alt="profile"
                />
              </div>
              <div className="editprofile-text-sec">
                <h3 className="mb-3">
                  <img
                    src="assets/icons/account-gold.png"
                    className="profile-icon"
                    alt={user_details.name}
                  />
                  <span className="middle capitalize">{user_details.name}</span>
                </h3>
                <h3 className="mt-0">
                  <img
                    src="assets/icons/email-gold.png"
                    className="profile-icon"
                    alt={user_details.name}
                  />
                  <span className="middle">{user_details.email}</span>
                </h3>
                <Link to={"/edit-profile"}>
                  <button className="btn btn-warning btn-solid">
                    edit profile
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Profile;
