import React, { Component } from "react";
import JPlayer, {
  Gui,
  SeekBar,
  BufferBar,
  Poster,
  Audio,
  Title,
  FullScreen,
  Mute,
  Play,
  PlayBar,
  VolumeBar,
  Duration,
  CurrentTime,
  Download,
  BrowserUnsupported,
  actions
} from "react-jplayer";
import JPlaylist, {
  initializeOptions,
  Playlist,
  Shuffle,
  Next,
  Previous,
  Repeat,
  TogglePlaylist,
  Remove,
  MediaLink,
  Title as PlaylistTitle
} from "react-jplaylist";

import api from "../../Environment";

import { apiConstants } from "../Constant/Constants";

const jPlayerOptions = {
  id: "AudioPlaylist",
  verticalVolume: true,
  autoPlay: true
};

const $ = window.$;

var jPlaylistOptions;

class AudioPlaylist extends Component {
  constructor(props) {
    super(props);

    this.callHistory = this.callHistory.bind(this);

    this.onPlay = this.onPlay.bind(this);

    this.onEnded = this.onEnded.bind(this);

    this.eventEmitter = this.props.eventEmitter;

    this.state = {
      current_song_id: "",

      isAuthenticated: this.props.isAuthenticated
    };
    this.listOfSongs = [];
  }

  componentDidMount() {
    // const { songs } = this.props;
    // this.listOfSongs = [];
    // for (var i = 0; i < songs.length; i++) {
    //   var song = songs[i];
    //   this.listOfSongs.push({
    //     id: song.song_id,
    //     title: song.title,
    //     artist: "",
    //     free: true,
    //     poster: song.picture,
    //     src: song.web_audio_url,
    //     sources: {
    //       mp3: song.web_audio_url
    //     }
    //   });
    // }
    // const check = actions.setPlaylist(jPlayerOptions.id, this.listOfSongs);
    // console.log("Triggered", check);
  }

  onPlay(e) {
    if (this.state.isAuthenticated) {
      var current_song_id = this.listOfSongs
        .filter(event => event.src === e.target.src)
        .map(output => {
          return output.id;
        });

      this.setState({
        current_song_id: current_song_id
      });

      this.callHistory(current_song_id, apiConstants.HISTORY_INITIATE_STATUS);
    }
  }

  onEnded(e) {
    console.log(this.state.current_song_id);

    if (this.state.isAuthenticated) {
      this.callHistory(
        this.state.current_song_id,
        apiConstants.HISTORY_COMPLETE_STATUS
      );
    }
  }

  callHistory(song_id, history_status) {
    api
      .postMethod("history/operations", {
        song_id: song_id,
        history_status: history_status
      })
      .then(
        response => {
          if (response.data.success) {
            console.log("History status " + history_status);
          } else {
            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  songviewTrigger() {
    this.eventEmitter.emit("open_songview");
  }

  openQueueTrigger() {
    this.eventEmitter.emit("open_queue");
  }

  // this.props.dispatch(
  //   actions.setPlaylist(jPlayerOptions.id, this.listOfSongs)
  // );
  // console.log("Triggered");

  render() {
    const { songs } = this.props;

    this.listOfSongs = [];

    for (var i = 0; i < songs.length; i++) {
      var song = songs[i];

      this.listOfSongs.push({
        id: song.song_id,
        title: song.title,
        artist: "",
        free: true,
        poster: song.picture,
        src: song.web_audio_url,
        sources: {
          mp3: song.web_audio_url
        }
      });
    }

    jPlaylistOptions = {
      hidePlaylist: true,
      playlist: this.listOfSongs,
      autoPlay: true
    };

    console.log("constructor", this.listOfSongs);

    initializeOptions(jPlayerOptions, jPlaylistOptions);

    return (
      <JPlaylist id={jPlayerOptions.id}>
        <JPlayer className="jp-sleek">
          <Audio onPlay={this.onPlay} onEnded={this.onEnded} />
          <Gui>
            <div className="jp-controls jp-icon-controls">
              <TogglePlaylist>
                <i className="fa fa-ellipsis-h" />
              </TogglePlaylist>
              <div className="jp-title-container">
                <div className="display-inline">
                  <div className="current-song-sec1" id="music-popup">
                    <div
                      className="relative"
                      onClick={this.songviewTrigger.bind(this)}
                    >
                      <Poster alt="current_song" className="song-img" />
                      <img
                        src="../../assets/icons/expand1.png"
                        alt="current_song"
                        className="expand-img"
                      />
                    </div>
                  </div>

                  <div className="current-song-sec2">
                    <h4>
                      <Title />
                    </h4>
                    {/* <h5> <div className="jp-current-duration"><CurrentTime /> | <Duration /></div></h5> */}
                  </div>
                  <div className="current-song-sec3">
                    <h5
                      className="pointer"
                      onClick={this.openQueueTrigger.bind(this)}
                    >
                      Queue
                    </h5>
                  </div>
                </div>
              </div>

              <div className="jp-progress">
                <SeekBar>
                  <BufferBar />
                  <PlayBar />
                  <CurrentTime />
                  <Duration />
                </SeekBar>
              </div>

              <div className="clearfix" />
              <div className="text-center m-t-10">
                <Repeat>
                  <i className="fa">{/* Icon set in css */}</i>
                  <i className="fa fa-repeat" />
                </Repeat>
                <Previous>
                  <i className="fa fa-step-backward" />
                </Previous>
                <Play>
                  <i className="fa">{/* Icon set in css */}</i>
                </Play>
                <Next>
                  <i className="fa fa-step-forward" />
                </Next>
                <Shuffle>
                  <i className="fa fa-random" />
                </Shuffle>
                <div className="jp-volume-container">
                  <Mute>
                    <i className="fa">{/* Icon set in css */}</i>
                  </Mute>
                  <div className="jp-volume-slider">
                    <div className="jp-volume-bar-container">
                      <VolumeBar />
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden-class">
                <div className="jp-playlist-container">
                  <Playlist>
                    <Remove />
                    <MediaLink>
                      <PlaylistTitle />
                    </MediaLink>
                  </Playlist>
                  {/*<TogglePlaylist><i className="fa fa-ellipsis-h" /></TogglePlaylist> */}
                </div>
              </div>
              {/*<FullScreen><i className="fa fa-expand" /></FullScreen>
                    <Download><i className="fa fa-download" /></Download> 
                    <div className="jp-title-container">
                      <Poster />
                      <Title />
                    </div> */}
            </div>
            <BrowserUnsupported />
          </Gui>
        </JPlayer>
      </JPlaylist>
    );
  }
}
export default AudioPlaylist;
