import React, { Component } from "react";

import api from "../../Environment";

import { Redirect } from "react-router-dom";

const $ = window.$;

class SingleAudio extends Component {
    constructor(props) {
        super(props);

        const {
            match: { params },
        } = this.props;

        this.state = {
            music_details: {},

            error_messages: "",

            isLoaded: false,

            musicID: params.id,

            isAuthenticated: this.props.data,

            redirect: false,
        };

        this.eventEmitter = this.props.eventEmitter;

        this.addQueue = this.addQueue.bind(this);

        this.wishlistOperation = this.wishlistOperation.bind(this);
    }

    wishlistOperation(e, song_id) {
        e.preventDefault();

        var currentTarget = e.target;

        api.postMethod("wishlist/operations", { song_id: song_id })
            .then(
                (response) => {
                    if (response.data.success) {
                        currentTarget.textContent =
                            response.data.wishlist_id > 0
                                ? "remove Favorite"
                                : "add Favorite";

                                $.toast({
                                    heading: "Success",
                                    text: response.data.message,
                                    icon: "success",
                                    position: "top-right",
                                    stack: false,
                                    textAlign: "left",
                                    loader: false,
                                    showHideTransition: "slide",
                                });
                    } else {
                        $.toast({
                            heading: "Error",
                            text: response.data.error,
                            icon: "error",
                            position: "top-right",
                            stack: false,
                            textAlign: "left",
                            loader: false,
                            showHideTransition: "slide",
                        });
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
            .catch((err) => {
                console.log("oops something went wrong");
            });
    }
    addQueue(e, object) {
        e.preventDefault();

        var queue_songs = [];

        queue_songs = localStorage.getItem("queue_songs")
            ? JSON.parse(localStorage.getItem("queue_songs"))
            : [];

        let already_value_exists = 0;

        if (queue_songs.length > 0) {
            if (queue_songs.some((item) => item.song_id === object.song_id)) {
                already_value_exists = 1;
            }
        }

        if (!already_value_exists) {
            this.eventEmitter.emit("queue_listen", object);

            $.toast({
                heading: "Success",
                text: "Added to queue",
                icon: "success",
                position: "top-right",
                stack: false,
                textAlign: "left",
                loader: false,
                showHideTransition: "slide",
            });
        } else {
            $.toast({
                heading: "Error",
                text: "Already Added to the queue",
                icon: "error",
                position: "top-right",
                stack: false,
                textAlign: "left",
                loader: false,
                showHideTransition: "slide",
            });
        }
    }

    musicDetailFn() {
        api.postMethod("song/details", { song_id: this.state.musicID })
            .then(
                (response) => {
                    if (response.data.success) {
                        this.setState({
                            isLoaded: true,

                            music_details: response.data.data,
                        });
                    } else {
                        $.toast({
                            heading: "Error",
                            text: response.data.error,
                            icon: "error",
                            position: "top-right",
                            stack: false,
                            textAlign: "left",
                            loader: false,
                            showHideTransition: "slide",
                        });

                        this.setState({
                            redirect: true,
                        });
                    }
                },

                (error) => {
                    this.setState({
                        isLoaded: true,

                        error_messages: error,
                    });
                }
            )
            .catch((err) => {
                this.setState({
                    isLoaded: true,

                    error_messages: "oops something went wrong",
                });
            });
    }
    componentDidMount() {
        this.musicDetailFn();
    }

    render() {
        const {
            music_details,
            isLoaded,
            error_messages,
            redirect,
            isAuthenticated,
        } = this.state;

        if (redirect) {
            return <Redirect to={"/home"} />;
        }

        if (error_messages) {
            return <div className="body">Error : {error_messages}</div>;
        } else if (!isLoaded) {
            return (
                <div className="body-content">
                    <div className="display-inline">
                        <div className="section-loader">
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                            <span className="loader-item"></span>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="body-content">
                    <div className="display-inline">
                        <div className="single-audio-img">
                            <img
                                src={music_details.picture}
                                onError={(e) => {
                                    e.target.onError = "";
                                    e.target.src =
                                        "../../assets/img/movie1.jpg";
                                }}
                                alt={music_details.title}
                            />
                        </div>
                        <div className="single-audio-details">
                            <h3>{music_details.title}</h3>
                            <h4
                                dangerouslySetInnerHTML={{
                                    __html: music_details.description,
                                }}
                            ></h4>
                            <div>
                                <button
                                    type="button"
                                    className="mt-1 btn btn-solid btn-warning"
                                    onClick={(e) =>
                                        this.addQueue(e, music_details)
                                    }
                                >
                                    play
                                </button>
                                <button
                                    to="#"
                                    data-toggle="modal"
                                    data-target="#sharebtn"
                                    className="mt-1 btn btn-solid btn-warning"
                                    onClick={(e) =>
                                        this.eventEmitter.emit(
                                            "share_details",
                                            music_details
                                        )
                                    }
                                >
                                    share
                                </button>
                                {isAuthenticated && (
                                    <button
                                        className="mt-1 btn btn-solid btn-warning"
                                        onClick={(e) =>
                                            this.wishlistOperation(
                                                e,
                                                music_details.song_id
                                            )
                                        }
                                    >
                                        {music_details.wishlist_status > 0
                                            ? "Remove Favorite"
                                            : "Add Favorite"}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 col-sm-4 col-md-4 col-xl-4">
                            <div className="p-3">
                                <h4 className="audio-text bold">album:</h4>
                            </div>
                        </div>
                        <div className="col-6 col-sm-8 col-md-8 col-xl-8">
                            <div className="p-3">
                                <h4 className="audio-text">
                                    {music_details.album_details.name}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <p className="audio-line"></p>
                    <div className="row">
                        <div className="col-6 col-sm-4 col-md-4 col-xl-4">
                            <div className="p-3">
                                <h4 className="audio-text bold">artist:</h4>
                            </div>
                        </div>
                        <div className="col-6 col-sm-8 col-md-8 col-xl-8">
                            <div className="p-3">
                                {/*{music_details.artists.length > 0 && music_details.artists
                                    .map(artist => <h4 className="audio-text" key={artist.artist_id}>{artist.name}</h4>)
                                .reduce((prev, curr) => [prev, ', ', curr])} */}
                                {music_details.artists.length > 0 &&
                                    music_details.artists.map((artist) => (
                                        <div
                                            className="mb-2"
                                            key={artist.artist_id}
                                        >
                                            <img
                                                src={artist.picture}
                                                className="music-artist-img"
                                                alt="music-artist-img"
                                            />
                                            <span className="audio-text">
                                                {artist.name}
                                            </span>
                                        </div>
                                    ))}
                                {music_details.artists.length <= 0 && "-"}
                            </div>
                        </div>
                    </div>
                    <p className="audio-line"></p>
                    <div className="row">
                        <div className="col-6 col-sm-4 col-md-4 col-xl-4">
                            <div className="p-3">
                                <h4 className="audio-text bold">category:</h4>
                            </div>
                        </div>
                        <div className="col-6 col-sm-8 col-md-8 col-xl-8">
                            <div className="p-3">
                                {/*{music_details.categories.length > 0 && music_details.categories
                                .map( category => <h4 className="audio-text" key={category.category_id}>{category.name}</h4>)
                            .reduce((prev, curr) => [prev, ', ', curr])} */}
                                {music_details.categories.length > 0 &&
                                    music_details.categories.map((category) => (
                                        <div
                                            className="mb-2"
                                            key={category.category_id}
                                        >
                                            <img
                                                src={category.picture}
                                                className="music-cate-img"
                                                alt="music-cate-img"
                                            />
                                            <span className="audio-text">
                                                {category.name}
                                            </span>
                                        </div>
                                    ))}
                                {music_details.categories.length <= 0 && "-"}
                            </div>
                        </div>
                    </div>
                    <p className="audio-line"></p>
                </div>
            );
        }
    }
}

export default SingleAudio;
