import React, {Component} from 'react';

import api from '../../Environment';

import {Redirect} from 'react-router-dom';

import {apiConstants} from '../../components/Constant/Constants';

const $ = window.$;

class DeleteAccount extends Component {

    constructor(props) {

        super(props);

        this.state = {

            delete_account : {

                password : "",

                login_by : "",

            },

            redirect : false

        }

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {

        const { name, value } = event.target;

        const { delete_account } = this.state;

        this.setState({ 
            delete_account : {...delete_account, [name]: value }
        });

    }

    handleSubmit(event) {

        event.preventDefault();

        if (!event.target.checkValidity()) {

            // form is invalid! so we do nothing

            return;

        }

        // form is valid! We can parse and submit data

        api.postMethod("delete_account", this.state.delete_account)
        .then(

            (response)=> {

                console.log(response.data);

                if (response.data.success) {

                    localStorage.removeItem('accessToken');

                    localStorage.removeItem('userId');

                    localStorage.removeItem('queue_songs');

                    $.toast({
                        heading: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                    this.setState({redirect : true});                    

                } else {

                   $.toast({
                        heading: 'Error',
                        text: response.data.error,
                        icon: 'error',
                        position: 'top-right',
                        stack: false,
                        textAlign: 'left',
                        loader : false,
                        showHideTransition: 'slide'
                    });

                }

            },
            (error) => {

                console.log(error);


            },
        )
        .catch(
            (err) => {

               console.log("oops something went wrong");

            }
        );
    }

    userProfile() {

        api.postMethod("profile", {})
        .then(

            (response)=> {

                if (response.data.success) {

                    localStorage.setItem('accessToken', response.data.data.token);

                    localStorage.setItem('userId', response.data.data.user_id);

                    this.setState({

                        delete_account : {

                            login_by : response.data.data.login_by
            
                        }
                    });

                   

                } else {

                   console.log(response.data.error);


                }

            },
            (error) => {

                console.log(error);


            },
        )
        .catch(
            (err) => {

               console.log("oops something went wrong");

            }
        );

    }

    componentDidMount() {

        this.userProfile();

    }

    render() {

        const { delete_account } = this.state;

        if (this.state.redirect) {

            return <Redirect to={'/'} />;
            
        }

        return (

            <div className="body-content">
                <div className="music-section">
                    <h2>We are sad to see you go</h2>
                    <p className="capitalize mb-4">note: Once your account is deleted, you would be losing your history and song details.</p>
                    <div className="row">
                        <div className="col-sm-12 col-md-8 col-lg-10 col-xl-8">
                            <form className="auth-form" onSubmit={this.handleSubmit}>
                            
                                {delete_account.login_by === apiConstants.LOGIN_BY && 
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <img src="../../assets/icons/lock-gold.png" className="auth-img" alt="icon" />
                                            </span>
                                        </div>
                                        <input type="password" className="form-control" placeholder="password" name="password" maxLength="128" minLength="6" title="Enter minumum 6 and maximum 128 characters" onChange={this.handleChange} required/>
                                    </div>
                                }
                                <div className="">
                                    <button className="btn btn-warning mt-0 btn-solid">delete account</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>					
            </div>

            

        );

    }

}

export default DeleteAccount;