import React, { Component } from "react";
import Background from "../../assets/img/see-all.jpg";
import { Link } from "react-router-dom";

import api from "../../Environment";

var sectionStyle = {
  backgroundImage: `url(${Background})`
};

const $ = window.$;

class Trending extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error_messages: "",

      isLoaded: false,

      items: [],

      no_of_songs: 0,

      isAuthenticated: this.props.data,

      sectionLoader: false,

      skipCount: 0
    };

    this.wishlistOperation = this.wishlistOperation.bind(this);

    this.showMore = this.showMore.bind(this);

    this.eventEmitter = this.props.eventEmitter;
  }

  wishlistOperation(e, song_id) {
    e.preventDefault();

    var currentTarget = e.target;

    api
      .postMethod("wishlist/operations", { song_id: song_id })
      .then(
        response => {
          if (response.data.success) {
            console.log(response.data);

            currentTarget.textContent =
              response.data.wishlist_id > 0
                ? "remove from Favorite"
                : "add to Favorite";

            $.toast({
              heading: "Success",
              text: response.data.message,
              icon: "success",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          } else {
            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  trendingList(skip) {
    if (this.state.skipCount > 0) {
      this.setState({
        sectionLoader: true
      });
    }

    api
      .postMethod("trending/songs", { skip: skip, type: "trending" })
      .then(
        response => {
          if (response.data.success) {
            if (this.state.skipCount > 0) {
              var items = [...this.state.items, ...response.data.data];

              this.setState({
                isLoaded: true,

                items: items,

                no_of_songs: response.data.no_of_songs,

                skipCount: this.state.skipCount + response.data.data.length,

                sectionLoader: false
              });
            } else {
              this.setState({
                isLoaded: true,

                items: response.data.data,

                no_of_songs: response.data.no_of_songs,

                skipCount: response.data.data.length
              });
            }
          } else {
            this.setState({
              isLoaded: true,

              error_messages: response.data.error
            });
          }
        },

        error => {
          console.log(error);

          this.setState({
            isLoaded: true,

            error_messages: error
          });
        }
      )
      .catch(err => {
        this.setState({
          isLoaded: true,

          error_messages: "oops something went wrong"
        });
      });
  }

  addQueue(e, object) {
    e.preventDefault();

    var queue_songs = [];

    queue_songs = localStorage.getItem("queue_songs")
      ? JSON.parse(localStorage.getItem("queue_songs"))
      : [];

    let already_value_exists = 0;

    if (queue_songs.length > 0) {
      if (queue_songs.some(item => item.song_id === object.song_id)) {
        already_value_exists = 1;
      }
    }

    if (!already_value_exists) {
      this.eventEmitter.emit("queue_listen", object);

      $.toast({
        heading: "Success",
        text: "Added to queue",
        icon: "success",
        position: "top-right",
        stack: false,
        textAlign: "left",
        loader: false,
        showHideTransition: "slide"
      });
    } else {
      $.toast({
        heading: "Error",
        text: "Already Added to the queue",
        icon: "error",
        position: "top-right",
        stack: false,
        textAlign: "left",
        loader: false,
        showHideTransition: "slide"
      });
    }
  }

  removeFromQueue(e, song_id) {
    e.preventDefault();

    var queue_songs = localStorage.getItem("queue_songs")
      ? JSON.parse(localStorage.getItem("queue_songs"))
      : [];

    if (queue_songs.length > 0) {
      queue_songs.some((item, index) => {
        if (item.song_id === song_id) {
          queue_songs.splice(index, 1);

          localStorage.setItem("queue_songs", JSON.stringify(queue_songs));

          queue_songs = localStorage.getItem("queue_songs")
            ? JSON.parse(localStorage.getItem("queue_songs"))
            : [];

          this.setState({
            queue_songs: queue_songs
          });

          this.eventEmitter.emit("remove_queue_listen", true);

          $.toast({
            heading: "Success",
            text: "Removed from the queue",
            icon: "success",
            position: "top-right",
            stack: false,
            textAlign: "left",
            loader: false,
            showHideTransition: "slide"
          });
        }
      });
    }
  }

  showMore(e) {
    this.trendingList(this.state.skipCount);
  }

  componentDidMount() {
    this.trendingList(0);
  }

  render() {
    const {
      error_messages,
      isLoaded,
      items,
      no_of_songs,
      isAuthenticated,
      sectionLoader
    } = this.state;

    if (error_messages) {
      return <div className="body-content">Error : {error_messages}</div>;
    } else if (!isLoaded) {
      return (
        <div className="body-content">
          <div className="pl-3 pr-3">
            <div className="section-loader">
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
              <span className="loader-item" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="body-content">
          <div className="see-all-banner" style={sectionStyle}>
            <div className="see-all-overlay">
              <div>
                <h2>trending</h2>
                <h4>{no_of_songs} songs</h4>
                <div className="button-sec">
                  {/* <button className="btn btn-warning btn-solid">
                    play all
                  </button>
                  <button
                    className="btn btn-warning btn-outline"
                    disabled
                    title="This Option Only avilable in App's"
                  >
                    download
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          {items.length <= 0 && (
            <div className="no-resultsec">
              <img
                src="../../assets/img/no-result.png"
                className="no-result-img"
                alt="no-result"
              />
              <h4>Songs is empty</h4>
              <p>No results found</p>
            </div>
          )}
          {items.length > 0 &&
            items.map((item, index) => (
              <div key={item.song_id}>
                <div className="display-inline song-list">
                  <Link to={`/music/${item.song_id}`}>
                    <div className="queue-sec1">
                      {/*<span>{index+1}</span> */}
                      <div className="relative inline">
                        <img
                          src={item.picture}
                          onError={e => {
                            e.target.onerror = "";
                            e.target.src = "../../assets/img/song1.jpg";
                          }}
                          className="song-img"
                          alt="song_img"
                        />
                        <img
                          src="../../assets/icons/playicon1.png"
                          className="song-play-icon"
                          alt="play_icon"
                        />
                      </div>
                    </div>
                    <div className="queue-sec2">
                      <h4>{item.title}</h4>
                      <h5
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </div>
                  </Link>
                  <div className="queue-sec3 list">
                    <Link
                      to={"/"}
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <img
                        src="../../assets/icons/more-horizondal-grey.png"
                        alt="download-arrow"
                      />
                    </Link>
                    <div className="dropdown-menu">
                      <div
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#sharebtn"
                        onClick={e =>
                          this.eventEmitter.emit("share_details", item)
                        }
                      >
                        share
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={e => this.addQueue(e, item)}
                      >
                        add to queue
                      </div>
                      {/*<div className="dropdown-item" onClick={e => this.removeFromQueue(e,item.song_id)}>remove from queue</div>*/}
                      {isAuthenticated && (
                        <div
                          className="dropdown-item"
                          onClick={e => this.wishlistOperation(e, item.song_id)}
                        >
                          {item.wishlist_status > 0
                            ? "remove from Favorite"
                            : "add to Favorite"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <p className="queue-line" />
              </div>
            ))}

          {sectionLoader && (
            <div className="pl-3 pr-3">
              <div className="section-loader">
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
                <span className="loader-item" />
              </div>
            </div>
          )}

          {items.length > 0 && (
            <div className="text-right">
              <button
                className="btn btn-solid btn-warning mr-0"
                onClick={e => this.showMore(e)}
              >
                show more
              </button>
            </div>
          )}
        </div>
      );
    }
  }
}

export default Trending;
