import React, { Component} from 'react';
import {Link} from  'react-router-dom';

import api from '../../Environment';

class Album extends Component {

    constructor(props) {

        super(props);

        const {match : {params}} = this.props;

        this.state = {

            error_messages : '',

            isLoaded : false,

            items : [],

            sectionLoader : false,

            skipCount : 0,

            search_key : (params.key !==undefined && params.key !== null && params.key !== '') ? params.key : ''

        };

        this.showMore = this.showMore.bind(this);
        
    }


    showMore(e) {

        this.albumList(this.state.skipCount);

    }

    albumList(skip) {

        if (this.state.skipCount > 0) {

            this.setState({

                sectionLoader : true

            })

        }

        var url = "albums/list";

        var object = {skip:skip};

        if (this.state.search_key) {

            url = "search/albums";

            object = {skip:skip, term : this.state.search_key, type : 'albums'};
        }

        api.postMethod(url, object)
        .then(
            (response) => {

                if (response.data.success) {

                    if (this.state.skipCount > 0) {

                        var items = [...this.state.items, ...response.data.data];

                        this.setState({

                            isLoaded : true,

                            items : items,

                            skipCount : this.state.skipCount+response.data.data.length,

                            sectionLoader : false

                        });

                    } else {

                        this.setState({

                            isLoaded : true,

                            items : response.data.data,

                            no_of_songs : response.data.no_of_songs,

                            skipCount : response.data.data.length

                        });
                    }

                } else {

                    this.setState({

                        isLoaded : true,

                        error_messages : response.data.error

                    });

                }

            },

            (error) => {

                console.log(error);

                this.setState({

                    isLoaded : true,

                    error_messages : error
                    
                });


            },
        )
        .catch(
            (err) => {

                this.setState({

                    isLoaded : true,

                    error_messages : "oops something went wrong"
                    
                });

            }
        );

    }

    componentDidMount() {

        this.albumList(0);

    }

    render() {

        
        const {error_messages, isLoaded, items, sectionLoader} = this.state;

        if (error_messages) {

            return (<div className="body-content">Error : {error_messages}</div>)

        } else if (!isLoaded) {

            return (<div className="body-content">

                <div className="pl-3 pr-3">
                    <div className="section-loader">
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                        <span className="loader-item"></span>
                    </div>
                </div>

            </div>)

        } else {

            return (
                
                <div className="body-content">
                    <div className="music-section">
                        <h2>albums</h2>
                        {items.length <= 0 &&
                            <div className="no-resultsec">
                                <img src="../../assets/img/no-result.png" className="no-result-img" alt="no-result"  />
                                <h4>Songs is empty</h4>
                                <p>No results found</p>
                            </div>
                        }
                        <div className="row">
                            {items.length > 0 && items.map((item,index)=> (
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4" key={item.movie_album_id}>
                                    <Link to={`/album-view/${item.movie_album_id}`}>
                                        <div className="albums-grid-sec">
                                            <div className="relative">
                                                <img src={item.picture} onError={(e)=>{e.target.onError=''; e.target.src="assets/img/song1.jpg"}} className="albums-grid-img" alt={item.name} />
                                                <div className="trending-overlay">
                                                    <img src="assets/icons/playicon.png" className="playicon" alt="playicon" />
                                                </div>
                                            </div>
                                            <div className="albums-details">
                                                <h4 className="title">{item.name}</h4>
                                                <h5 className="name">{item.description}</h5>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        {
                            sectionLoader && 
                            <div className="pl-3 pr-3">
                                <div className="section-loader">
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                    <span className="loader-item"></span>
                                </div>
                            </div>

                        }

                        {
                            items.length > 0 &&
                            <div className="text-right">
                                <button className="btn btn-solid btn-warning mr-0" onClick={e => this.showMore(e)}>show more</button>
                            </div>
                        }
                    </div>
                </div>

            );

        }

    }

}

export default Album;