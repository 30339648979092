import React, {Component} from 'react';

class SingleColumnLayout extends Component {
    render() {
        return (
            <div>
                {this.props.children}    
            </div>
        )
    }
}
export default SingleColumnLayout;

