import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";

import Background from "../../assets/img/sidebar.png";

import api from "../../Environment";

import SocialButton from "./SocialButton";

import { apiConstants } from "../Constant/Constants";
import configuration from "react-global-configuration";

var sectionStyle = {
  backgroundImage: `url(${Background})`
};

const $ = window.$;

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      register_form: {
        email: "",

        password: "",

        name: ""
      },

      home_redirect: false,

      redirect: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;

    const { register_form } = this.state;

    this.setState({
      register_form: { ...register_form, [name]: value }
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      // form is invalid! so we do nothing

      return;
    }

    // form is valid! We can parse and submit data

    api
      .postMethod("register", this.state.register_form)
      .then(
        response => {
          console.log(response.data);

          if (response.data.success) {
            localStorage.setItem("accessToken", response.data.data.token);

            localStorage.setItem("userId", response.data.data.user_id);

            $("#register-close").click();

            $.toast({
              heading: "Success",
              text: "Successfully, completed your Registration process",
              icon: "success",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });

            setTimeout(() => {
              this.setState({ redirect: true });
            }, 1000);
          } else {
            if (response.data.error_code === 1001) {
              $.toast({
                heading: "Success",
                text: response.data.error,
                icon: "success",
                position: "top-right",
                stack: false,
                textAlign: "left",
                loader: false,
                showHideTransition: "slide"
              });

              $("#register-close").click();

              setTimeout(() => {
                this.setState({ home_redirect: true });
              }, 1000);
            } else {
              $.toast({
                heading: "Error",
                text: response.data.error,
                icon: "error",
                position: "top-right",
                stack: false,
                textAlign: "left",
                loader: false,
                showHideTransition: "slide"
              });
            }
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log("oops something went wrong");
      });
  }

  handleSocialLoginFailure(err) {
    console.error(err);
  }

  handleSocialLogin(user, provider) {
    console.log(user.profile);

    var profile_details = user.profile;

    var details = {
      social_unique_id: profile_details.id,

      first_name: profile_details.firstName,

      last_name: profile_details.lastName,

      name: profile_details.name,

      email: profile_details.email,

      picture: profile_details.profilePicURL,

      login_by: provider
    };

    api
      .postMethod("register", details)
      .then(
        response => {
          if (response.data.success) {
            localStorage.setItem("accessToken", response.data.data.token);

            localStorage.setItem("userId", response.data.data.user_id);

            this.closeRegister();

            $.toast({
              heading: "Success",
              text: "Successfully, completed your Registration process",
              icon: "success",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });

            this.setState({ redirect: true });
          } else {
            $.toast({
              heading: "Error",
              text: response.data.error,
              icon: "error",
              position: "top-right",
              stack: false,
              textAlign: "left",
              loader: false,
              showHideTransition: "slide"
            });
          }
        },
        error => {
          console.log(error);
        }
      )
      .catch(err => {
        console.log(err);

        console.log("oops something went wrong");
      });
  }

  closeRegister() {
    $("#register-close").click();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/profile"} />;
    }

    if (this.state.home_redirect) {
      return <Redirect to={"/"} />;
    }

    return (
      <div className="modal fade" id="register">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="row m-0">
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 p-0">
                <div style={sectionStyle} className="auth-bg-img" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 p-0 auth-bg-content">
                <div className="modal-header">
                  <h4 className="modal-title">welcome to {configuration.get("configData.site_name")}!</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    id="register-close"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body auth-form">
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img
                            src="assets/icons/account-gold.png"
                            className="auth-img"
                            alt="icon"
                          />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="username"
                        required
                        name="name"
                        minLength="2"
                        maxLength="128"
                        title="Enter Minimum 2 and Maximum 128 Characters"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img
                            src="assets/icons/email-gold.png"
                            className="auth-img"
                            alt="icon"
                          />
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="email"
                        required
                        name="email"
                        minLength="2"
                        maxLength="128"
                        title="Enter Minimum 2 and Maximum 128 Characters"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <img
                            src="assets/icons/lock-gold.png"
                            className="auth-img"
                            alt="icon"
                          />
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="password"
                        required
                        name="password"
                        minLength="6"
                        maxLength="128"
                        title="Enter Minimum 6 and Maximum 128 Characters"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="text-center">
                      <button className="btn btn-warning btn-solid mt-0">
                        register
                      </button>
                    </div>
                    <div className="text-center">
                      <p className="mt-3 mb-0 capitalize">
                        already have an account?
                        <Link
                          to="#"
                          data-toggle="modal"
                          data-target="#login"
                          className="link"
                          id="login-link"
                          onClick={this.closeRegister}
                        >
                          &nbsp;login
                        </Link>
                      </p>
                    </div>
                  </form>

                  <div className="login-separator">Or login with</div>
                  <div className="text-center">
                    <SocialButton
                      provider="facebook"
                      appId={apiConstants.FACEBOOK_APP_ID}
                      onLoginSuccess={e =>
                        this.handleSocialLogin(e, "facebook")
                      }
                      onLoginFailure={this.handleSocialLoginFailure}
                      className="social-login"
                    >
                      <img
                        src="../../assets/icons/social-facebook-gold.png"
                        alt="facebook"
                      />
                    </SocialButton>
                    <SocialButton
                      provider="google"
                      appId={apiConstants.GOOGLE_CLIENT_ID}
                      onLoginSuccess={e => this.handleSocialLogin(e, "google")}
                      onLoginFailure={this.handleSocialLoginFailure}
                      className="social-login"
                    >
                      <img
                        src="../../assets/icons/social-google-plus-gold.png"
                        alt="google"
                      />
                    </SocialButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
